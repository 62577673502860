import _ from 'lodash'

const usePortraitPhotos = () => {
    const fixImageOrientation = async (image) => {
        let allMetaData
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        let width = image.width
        let height = image.height
        let exifOrientation = ''

        window.EXIF.getData(image, function () {
            allMetaData = window.EXIF.getAllTags(this)
            exifOrientation = allMetaData.Orientation
        })

        if (_.includes([5, 6, 7, 8], exifOrientation)) {
            canvas.width = height
            canvas.height = width
        } else {
            canvas.width = width
            canvas.height = height
        }

        switch (exifOrientation) {
            case 2:
                ctx.setTransform(-1, 0, 0, 1, width, 0)
                break
            case 3:
                ctx.setTransform(-1, 0, 0, -1, width, height)
                break
            case 4:
                ctx.setTransform(1, 0, 0, -1, 0, height)
                break
            case 5:
                ctx.setTransform(0, 1, 1, 0, 0, 0)
                break
            case 6:
                ctx.setTransform(0, 1, -1, 0, height, 0)
                break
            case 7:
                ctx.setTransform(0, -1, -1, 0, height, width)
                break
            case 8:
                ctx.setTransform(0, -1, 1, 0, 0, width)
                break
            default:
                ctx.setTransform(1, 0, 0, 1, 0, 0)
        }

        ctx.drawImage(image, 0, 0, width, height)

        let dataUrl = canvas.toDataURL()
        let imgElement = document.createElement('img')
        imgElement.src = dataUrl
        imgElement.style.width = `${canvas.width}px`
        imgElement.style.height = `${canvas.height}px`

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.log('Canvas is invalid')
                    return
                }
                resolve(blob)
            }, 'image/jpeg')
        })
    }

    return { fixImageOrientation }
}

export default usePortraitPhotos