import React, { useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'
import NotFoundView from '../ConversationWindow/NotFoundView'
import ConversationsView from '../SideBar/ConversationsView'
import useSiblingWidth from '../../hooks/siblingWidthHook'
import useHookWithRefCallback from '../../hooks/refCallbackHook'
import { allcalAppUrl } from '../../server'

const MobileView = () => {
    return <Hidden smUp>
        <Grid item xs={12} className='full-height'>
            <Slide direction='up' in>
                <div className='full-height'>
                    <NotFoundView />
                </div>
            </Slide>
        </Grid>
    </Hidden>
}

const DesktopView = () => {
    const { maxWidth, setSibling } = useSiblingWidth()
    const [refSibling] = useHookWithRefCallback(setSibling)

    return <Hidden xsDown>
        <Grid item sm={5} md={5} lg={4} style={{ minWidth: '305px' }} ref={refSibling}>
            <ConversationsView />
        </Grid>
        <Grid item sm={7} md={7} lg={8} style={{ maxWidth: `${maxWidth}px` }}>
            <NotFoundView />
        </Grid>
    </Hidden>
}

const NotFoundPage = ({ history }) => {

    useEffect(() => {
        setTimeout(() => {
            history.push('/conversations')
            window.parent.postMessage({}, allcalAppUrl)
        }, 10000)
    }, [])

    return <Fragment>
        <MobileView />
        <DesktopView />
    </Fragment>
}

NotFoundPage.propTypes = {
    history: PropTypes.object
}

export default withRouter(NotFoundPage)