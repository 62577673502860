import React, { Fragment, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import Add from '@material-ui/icons/Add'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import StyledSectionHeader from './StyledSectionHeader'
import StyledSectionContent from './StyledSectionContent'
import ConversationParticipant from './ConversationParticipant'
import ListInfiniteScroll from '../common/ListInfiniteScroll'
import { ConversationsContext } from '../../managers/ConversationsManager'
import useWindowSize from '../../hooks/windowSizeHook'

const ConversationParticipants = ({ isUserAdmin, users, me, setIsAddingParticipants }) => {
    const { setContactsView, openedConversation, hasMoreParticipants,
        refreshConversationParticipantsList } = useContext(ConversationsContext)
    const { listMaxHeight, setSiblingHeight } = useWindowSize(437)
    // let timer = null

    const setListHeight = () => {
        const actualHeight = users.length * 56
        if (actualHeight < listMaxHeight) {
            return actualHeight + 49
        }
        return listMaxHeight - 15
    }

    const openAddParticipantsView = () => {
        setIsAddingParticipants(true)
        setContactsView('addParticipants')
    }

    //  Temporarily disabled due to long tapping on scrollable list
    // const handleListScroll = () => {
    //     setIsListScrolling(true)

    //     window.clearTimeout(timer)

    //     timer = setTimeout(() => {
    //         setIsListScrolling(false)
    //     }, 500)
    // }

    useEffect(() => {
        if (_.isEmpty(openedConversation.description)) {
            setSiblingHeight(437)
        } else {
            setSiblingHeight(482)
        }
    }, [])

    return <Fragment>
        <StyledSectionHeader title='participants' data-testid='participants-section-header' />
        <StyledSectionContent>
            <List dense component='ul' data-testid='participants-section-content'>
                <ListInfiniteScroll
                    dataLength={users.length}
                    height={setListHeight()}
                    endDelimiterHeight={32}
                    hasMore={hasMoreParticipants}
                    next={() => refreshConversationParticipantsList(openedConversation.conversationId,
                        false, openedConversation.participants.length)}
                    refreshFunction={() => refreshConversationParticipantsList(openedConversation.conversationId, true)}
                >
                    <Fragment>
                        {users.map(user => <ConversationParticipant
                            key={user.userId}
                            conversation={openedConversation}
                            participant={user}
                            me={me} />)}
                    </Fragment>
                </ListInfiniteScroll>
                {isUserAdmin &&
                    <ListItem button onClick={openAddParticipantsView} data-testid='add-participant-btn'>
                        <ListItemAvatar>
                            <StyledAvatar alt='user-photo'>
                                <Add />
                            </StyledAvatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary='Add new participant'
                        />
                    </ListItem>
                }
            </List>
        </StyledSectionContent>
    </Fragment>
}

const StyledAvatar = styled(Avatar)`
    && {
        background-color: #e6e6e6;
        color: #ababab;
    }
`

ConversationParticipants.propTypes = {
    isUserAdmin: PropTypes.bool,
    setIsAddingParticipants: PropTypes.func,
    me: PropTypes.object,
    users: PropTypes.arrayOf(PropTypes.object)
}

export default ConversationParticipants