import { create } from 'apisauce'
import React from 'react'

if (process.env.NODE_ENV !== 'production') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React);
}

const getAllcalApiUrl = () => {
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development': return 'https://dev.api.allcal.com/'
        case 'test': return 'https://test.api.allcal.com/'
        case 'staging': return 'https://staging.api.allcal.com/'
        case 'production': return 'https://api.allcal.com/'
        default: return 'https://dev.api.allcal.com/'
    }
}

const getChatApiUrl = () => {
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development': return 'https://api.messenger.allcal.com/dev'
        case 'test': return 'https://api.messenger.allcal.com/test'
        case 'staging': return 'https://api.messenger.allcal.com/staging'
        case 'production': return 'https://api.messenger.allcal.com/live'
        default: return 'https://api.messenger.allcal.com/dev'
    }
}

const getAuthEndPoint = () => {
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development': return 'https://api.notifications.allcal.com/dev/topics/auth'
        case 'test': return 'https://api.notifications.allcal.com/test/topics/auth'
        case 'staging': return 'https://api.notifications.allcal.com/staging/topics/auth'
        case 'production': return 'https://api.notifications.allcal.com/live/topics/auth'
        default: return 'https://api.notifications.allcal.com/dev/topics/auth'
    }
}

const getPusherSettings = () => {
    const authEndpoint = getAuthEndPoint()
    let appKey = ''

    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development': appKey = '73848b5062f9c4690f8e'
            break
        case 'test': appKey = '73848b5062f9c4690f8e'
            break
        case 'staging': appKey = '55da3ab5a1a8018bcced'
            break
        case 'production': appKey = 'bd495557a75b797ee19b'
            break
        default: appKey = '73848b5062f9c4690f8e'
    }

    return { appKey, authEndpoint }
}

const getAllcalAppUrl = () => {
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development': return 'https://dev.app.allcal.com/'
        case 'test': return 'https://test.app.allcal.com/'
        case 'staging': return 'https://staging.app.allcal.com/'
        case 'production': return 'https://app.allcal.com/'
        default: return 'https://dev.app.allcal.com/'
    }
}

const getFirebaseUrl = () => {
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'development': return 'https://api.notifications.allcal.com/dev/devices'
        case 'test': return 'https://api.notifications.allcal.com/test/devices'
        case 'staging': return 'https://api.notifications.allcal.com/staging/devices'
        case 'production': return 'https://api.notifications.allcal.com/live/devices'
        default: return 'https://api.notifications.allcal.com/dev/devices'
    }
}

const API = create({
    baseURL: getChatApiUrl(),
    headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/json',
        'service': 'Allcal'
    }
})

export const getInvisibleRecaptchaSiteKey = () => {
    if (window.location.hostname === 'messenger.allcal.com') {
        switch (process.env.REACT_APP_BUILD_ENV) {
            case 'development': return '6LfCIGgaAAAAAA07oX6KPtoe39f3SR-CTtz43vi2'
            case 'staging': return '6Le0IGgaAAAAACBxffbRLuzt5pIRIwmeMXDJqkZs'
            case 'production': return '6LejIGgaAAAAADF-R55JpBcEW_wX1B8YSEiqk7BX'
            default: return '6LfCIGgaAAAAAA07oX6KPtoe39f3SR-CTtz43vi2'
        }
    } else {
        switch (process.env.REACT_APP_BUILD_ENV) {
            case 'development': return '6LcUj_oUAAAAAP8HEujqevIUNVuJM6Qym94Sxyeh'
            case 'staging': return '6Lc2j_oUAAAAALg5y6xfiiBNVjZVVjK_INSfUhcO'
            case 'production': return '6LdBj_oUAAAAAKy1mo0MINJvPKsgMQCcSGxEOpuh'
            default: return '6LcUj_oUAAAAAP8HEujqevIUNVuJM6Qym94Sxyeh'
        }
    }

}

export const allCalAPI = create({
    baseURL: getAllcalApiUrl(),
    headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    }
})

export const pusherSettings = getPusherSettings()
export const firebaseUrl = getFirebaseUrl()
export const environment = process.env.REACT_APP_BUILD_ENV || 'development'
export const allcalAppUrl = getAllcalAppUrl()
export default API
