import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'
import Person from '@material-ui/icons/Person'
import UserAvatarInitials from '../common/UserAvatarInitials'

const UserAvatar = ({ user }) => {
    const userHasAvatar = () => !_.isEmpty(user) && !user.isBlocked &&
        (!_.isEmpty(user.imageLink) || !_.isEmpty(user.photo))
    const isUserAvailable = () => !_.isEmpty(user) && !user.isBlocked &&
        !_.includes(['deleted', 'unavailable'], user.status) &&
        user.userId !== 'nonAllcalUser'

    return <div data-testid='user-avatar-wrapper'>
        {userHasAvatar() ?
            <Avatar alt={user.name} user={user} src={user.imageLink || user.photo} data-testid='user-avatar-photo' /> :
            (isUserAvailable() ? <UserAvatarInitials name={user.name} email={user.email} /> :
                <StyledAvatar data-testid='user-placeholder'><Person /></StyledAvatar>
            )
        }
    </div>
}

const StyledAvatar = styled(Avatar)`
    && {
        background-color: #D0D0D0;
        color: #9B9B9B;
    }
`

UserAvatar.propTypes = {
    user: PropTypes.object.isRequired
}

UserAvatar.defaultProps = {
    user: {}
}

export default UserAvatar