import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'
import formatDate from '../../utils/dateFormatter'
import { groupMessagesBySender } from '../../utils/messages'
import MessageGroup from './MessageGroup'
import Chip from '@material-ui/core/Chip'
import ConversationSystemMessage from './ConversationSystemMessage'

const SystemMessageGroup = ({ messageGroup }) => <StyledSystemMessages>
    {_.map(messageGroup.messages, messageItem => <ConversationSystemMessage key={messageItem.messageId}
        messageItem={messageItem} />)}
</StyledSystemMessages>

const MessagesList = ({ date, messages }) => {
    return <StyledMessagesWrapper>
        {_.map(groupMessagesBySender(messages),
            (messageGroup, idx) => {
                // Should identify system more accurately
                return messageGroup.sender.name === 'System' ?
                  <SystemMessageGroup key={idx} messageGroup={messageGroup} /> : <MessageGroup
                    key={idx}
                    messageGroup={messageGroup}
                    data-testid={`message-group-${idx}`}
                />
            }
        )}
        <StyledSystemMessages>
            <Chip label={formatDate(date, true)} />
        </StyledSystemMessages>
    </StyledMessagesWrapper>
}

const MappedMessagesByDay = ({ mappedMessages }) => {
    return <Fragment>
        {!_.isEmpty(mappedMessages) &&
            _.map(mappedMessages, (value, key) => <MessagesList key={key} date={key} messages={value} />)
        }
    </Fragment>
}

const StyledMessagesWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    padding-top: 10px;
    width: 100%;
`

const StyledSystemMessages = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 10px;
    width: 100%;
`

MappedMessagesByDay.propTypes = {
    mappedMessages: PropTypes.object
}

MessagesList.propTypes = {
    date: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.object)
}

SystemMessageGroup.propTypes = {
    messageGroup: PropTypes.object
}

export default MappedMessagesByDay