import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const NotFoundView = () => {
    return <StyledWrapper>
        <StyledImg alt='404' src='../../../images/404_not_found.svg' />
        <StyledTypography variant='h5'> 404 chat not found </StyledTypography>
        <Typography variant='h5'> Looks like the link you followed is invalid.</Typography>
        <Typography variant='body2'> This will close automatically in 10 seconds.</Typography>
    </StyledWrapper>
}


const StyledImg = styled.img`
    width: 40%;
`

const StyledWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 24px;
    text-align: center;
`

const StyledTypography = styled(Typography)`
    && {
        margin: 30px;
    }
`

export default NotFoundView