import { useState } from 'react'

const useConfirmationDialog = () => {
    const [isDialogOpened, setIsDialogOpened] = useState(false)
    const [dialogSettings, setDialogSettings] = useState({})

    const triggerDialog = (settings) => {
        setIsDialogOpened(true)
        setDialogSettings(settings)
    }

    return {
        triggerDialog,
        isDialogOpened,
        setIsDialogOpened,
        dialogSettings
    }
}

export default useConfirmationDialog