import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const ItemActionsMenu = ({ options, isOpened, anchorEl, onClose }) => {

    const selectOption = (option) => {
        onClose()
        option.callback && option.callback()
    }

    return <Menu
        id='item-actions-menu'
        open={isOpened}
        onClose={onClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        data-testid='item-actions-menu'
    >
        {options.map((option, idx) =>
            <StyledMenuItem key={idx}
                className={option.className}
                onClick={() => selectOption(option)}
                data-testid='item-actions-menu-option'>{option.name}
            </StyledMenuItem>
        )}
    </Menu>
}

const StyledMenuItem = styled(MenuItem)`
    &.important {
        color: #e76d65;
    }
    &.disabled {
        pointer-events: none;
        color: #ababab;
    }
`

ItemActionsMenu.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    isOpened: PropTypes.bool,
    anchorEl: PropTypes.object,
    onClose: PropTypes.func
}

export default ItemActionsMenu