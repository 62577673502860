import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Image from '@material-ui/icons/Image'
import { withTheme } from '@material-ui/styles'
import ReactMarkdown from 'react-markdown'

const LastMessage = ({ conversation, theme }) => {

    const getLastMessageContent = () => {
        return conversation.lastMessage.message ? <ReactMarkdown unwrapDisallowed={true} disallowedTypes={['link', 'paragraph']}
            source={conversation.lastMessage.message} /> :
            <Fragment><StyledImage theme={theme} />Media</Fragment>
    }

    const shouldDisplaySenderName = () => {
        return conversation.type !== '1to1' && conversation.lastMessage.sender.name !== 'System'
    }

    return <Fragment>
        {conversation.lastMessage &&
            <span data-testid='conversation-secondary-text'>
                {shouldDisplaySenderName() &&
                    <StyledTypography color='textPrimary' component='span' conversation={conversation} variant='body2'
                        data-testid='secondary-text-prefix'>
                        {conversation.lastMessage.sender.name + ' - '}
                    </StyledTypography>
                }
                <StyledTypography color='textPrimary' component='span' conversation={conversation} variant='body2'
                    data-testid='secondary-text-name'>{getLastMessageContent()}</StyledTypography>
            </span>
        }
    </Fragment>
}

const StyledImage = styled(Image)`
    && {
        color: ${props => props.theme.palette.grey['800']};
        font-size: 16px;
        vertical-align: sub;
    }
`

const StyledTypography = styled(Typography)`
    && {
        display: inline;
        font-weight: ${props => props.conversation.unreadCount > 0 ? '600' : '400'};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

LastMessage.propTypes = {
    conversation: PropTypes.object,
    theme: PropTypes.object
}

LastMessage.defaultProps = {
    theme: {
        palette: {}
    }
}

export default withTheme(LastMessage)