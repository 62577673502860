import React, { useContext, Fragment } from 'react'
import LoadingLayer from './LoadingLayer'
import { AppGlobalContext } from '../../managers/AppManager'

const AppLoader = () => {
    const { areHeadersSet } = useContext(AppGlobalContext)

    return <Fragment>{!areHeadersSet && <LoadingLayer />}</Fragment>
}

export default AppLoader
