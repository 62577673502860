import API from '../server'
import { useState } from 'react'
import axios from 'axios'

const messagesPerPage = 20

const getS3Credentials = async (file) => {
    const urlResponse = await API.get('/s3', { type: file.type })
    return urlResponse.data
}

const sendMessage = async (messageObject, conversationId) => {
    const response = await API.post(`/conversations/${conversationId}/messages`, messageObject)
    return response.data
}

const getMessages = async (conversationId, skip, at, limit = messagesPerPage) => {
    const messagesResponse = await API.get(`/conversations/${conversationId}/messages`,
        { skip, limit, from: at })
    return messagesResponse.data
}

let cancelUploadRequest

const useMessages = () => {
    const [uploadProgress, setUploadProgress] = useState(0)

    const CancelToken = axios.CancelToken

    const uploadFile = async (file, url) => {
        let formData = new FormData()
        formData.append('image', file)

        const manageProgress = (value) => {
            setUploadProgress(value.loaded * 100 / value.total)

            if (value.loaded === value.total) {
                setUploadProgress(0)
            }
        }

        const cancelToken = new CancelToken(function executor(c) {
            cancelUploadRequest = c
        })

        const uploadedFileResponse = await axios.put(url, file, {
            headers: { 'Content-Type': file.type },
            onUploadProgress: value => manageProgress(value),
            cancelToken
        })
        return uploadedFileResponse
    }

    const cancelUpload = () => {
        cancelUploadRequest()
        setUploadProgress(0)
    }

    return {
        getS3Credentials,
        uploadFile,
        uploadProgress,
        cancelUpload,
        sendMessage,
        getMessages,
        messagesPerPage
    }
}

export default useMessages