// The server sends for an organization the following object:
// colorSettings = {
//  foreground: ''
//  highlight: ''
//  main: ''
//  secondaryHighlight: ''
// }

import { createMuiTheme } from '@material-ui/core/styles'
import createPalette from '@material-ui/core/styles/createPalette'
import { useEffect, useState } from 'react'

const useCustomAppTheme = () => {
    const defaultTheme = createMuiTheme()
    const [themeOptions, setThemeOptions] = useState({
        ...defaultTheme,
        typography: {
            useNextVariants: true,
            variant: 'h1',
            fontFamily: [
                'Open Sans',
                '-apple-system',
                'BlinkMacSystemFont',
                'Roboto',
                'Arial',
                'sans-serif',
            ].join(','),
        },
        palette: createPalette({
            ...defaultTheme.palette,
            primary: {
                main: '#273a44'
            },
            secondary: {
                main: '#49bedb'
            },
            error: {
                main: '#e76d65'
            }
        }),
        status: {
            danger: '#e76d65',
        },
    })

    const handleMessageEvent = (event) => {
        const organizationTheme = event.data.theme
        if (organizationTheme) {
            setThemeOptions(prevState => ({
                ...prevState, palette: {
                    ...prevState.palette,
                    primary: {
                        main: organizationTheme.main
                    },
                    secondary: {
                        main: organizationTheme.secondaryHighlight
                    }
                }
            }))
        }
    }

    useEffect(() => {
        window.addEventListener('message', handleMessageEvent, false)
        return () => {
            window.removeEventListener('message', handleMessageEvent, false)
        }
    }, [])
    return { themeOptions }
}

export default useCustomAppTheme
