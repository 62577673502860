import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import photoPlaceholderUtils from '../../utils/photoPlaceholder'
import Avatar from '@material-ui/core/Avatar'

const UserAvatarInitials = ({ name, email }) => {
    return <StyledAvatar name={name} email={email} data-testid='user-avatar-initials'>
        {photoPlaceholderUtils.getUserInitials(name)}
    </StyledAvatar>
}

const StyledAvatar = styled(Avatar)`
    && {
        background-color: ${props => photoPlaceholderUtils.getPlaceholderBackgroundColor(props.email || props.name)};
        color: #FFFFFF;
    }
`

UserAvatarInitials.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string
}

UserAvatarInitials.defaultProps = {
    name: '',
    email: ''
}

export default UserAvatarInitials