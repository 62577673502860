import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import StyledLink from '../common/StyledLink'

const MobileViewMenu = ({ options, type, position }) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false)

    const styles = {
        topRight: {
            maxHeight: 48 * 4.5,
            top: 24,
            right: 24,
        },
        bottomRight: {
            maxHeight: 48 * 4.5,
            bottom: 24,
            right: 24,
        }
    }
    const toggleMenu = () => {
        const currentMenuState = isMenuOpened
        setIsMenuOpened(!currentMenuState)
    }

    const selectOption = (option) => {
        toggleMenu()
        option.callback && option.callback()
    }

    const getOptionLayout = (type, idx, option) => {
        switch (type) {
            case 'actions':
                return <MenuItem key={idx} onClick={() => selectOption(option)} data-testid='menu-action-option'>{option.name}</MenuItem>
            case 'links':
                return <div key={idx}><StyledLink to={option.link} data-testid='menu-link-option'>
                    <MenuItem onClick={toggleMenu} data-testid='menu-item-link-option'>{option.name}</MenuItem>
                </StyledLink></div>
            default:
                return <MenuItem key={idx} onClick={() => selectOption(option)} data-testid='menu-action-option'>{option.name}</MenuItem>
        }
    }

    return <div>
        <StyledMenuIconButton
            aria-label='More'
            aria-owns={isMenuOpened ? 'long-menu' : undefined}
            aria-haspopup='true'
            onClick={toggleMenu}
        >
            <MoreVertIcon />
        </StyledMenuIconButton>
        <Menu
            id='long-menu'
            open={isMenuOpened}
            onClose={toggleMenu}
            anchorReference='none'
            PaperProps={{
                style: styles[position]
            }}
        >
            {options.map((option, idx) => getOptionLayout(type, idx, option))}
        </Menu>
    </div>
}

const StyledMenuIconButton = styled(IconButton)`
    && {
        padding: 8px;
    }
`

MobileViewMenu.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string,
    position: PropTypes.string
}

export default MobileViewMenu