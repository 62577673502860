import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'
import formatDate from '../../utils/dateFormatter'

const ConversationIndicators = ({ conversation }) => {
    return <StyledIndicatorsWrapper data-testid='conversation-indicators-wrapper'>
        <StyledBadge
            badgeContent={conversation.unreadCount}
            color='primary'
            unreadcount={conversation.unreadCount}
            data-testid='conversation-unread'
        >
            <span></span>
        </StyledBadge>
        <StyledTypography
            color='textPrimary'
            component='span'
            variant='caption'
            data-testid='conversation-date'
        >
            {formatDate(conversation.lastMessage.createdDate, true)}
        </StyledTypography>
    </StyledIndicatorsWrapper>
}

const StyledIndicatorsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 64px;
    text-align: right;
`

const StyledBadge = styled(Badge)`
    color: ${props => props.unreadcount > 0 ? '#ffffff' : 'inherit'};
    -webkit-text-fill-color: ${props => props.unreadcount > 0 ? '#ffffff' : 'inherit'};
    right: 8px;

    && {
        display: unset;
        vertical-align: unset;
    }
    
    span:last-child {
        height: 18px;
        min-width: 18px;
    }
`

const StyledTypography = styled(Typography)`
    position: relative;
    top: 8px;
`

ConversationIndicators.propTypes = {
    conversation: PropTypes.object
}

export default ConversationIndicators