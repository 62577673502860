import React, { Fragment, useState, useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/styles'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import ExpandMore from '@material-ui/icons/ExpandMore'
import useConversations from '../../hooks/conversationsHook'
import useConfirmationDialog from '../../hooks/confirmationDialogHook'
import useContacts from '../../hooks/contactsHook'
// import TouchableElement from '../common/TouchableElement'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ItemActionsMenu from '../common/ItemActionsMenu'
import ConfirmationDialog from '../common/ConfirmationDialog'
import UserAvatar from '../common/UserAvatar'
import appMessages from '../../utils/appMessages'
import { AppGlobalContext } from '../../managers/AppManager'
import { Typography } from '@material-ui/core';

const ConversationParticipant = ({ conversation, participant, me, theme }) => {
    const [isHovered, setIsHovered] = useState(false)
    const [isOptionsOpened, setIsOptionsOpened] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const { isMobileDevice } = useContext(AppGlobalContext)

    const { triggerDialog, isDialogOpened, dialogSettings, setIsDialogOpened } = useConfirmationDialog()
    const { isUserConversationAdmin, isUserConversationOwner, changeRole, removeParticipant } = useConversations()
    const { blockUser } = useContacts()

    const isMe = (participant) => {
        return participant.userId === me.userId
    }

    const changeParticipantRole = () => {
        changeRole(participant)
    }

    const removeParticipantFromConversation = () => {
        removeParticipant(conversation.conversationId, participant.userId)
    }

    const proceedWithConversationAction = (action) => {
        switch (action) {
            case 'blockUser':
                blockUser(participant)
                return
            case 'removeParticipant':
                removeParticipantFromConversation()
                return
            default:
                return
        }
    }

    const triggerBlockParticipant = () => {
        triggerDialog({
            title: 'Block user',
            action: 'blockUser',
            okCallback: proceedWithConversationAction,
            content: appMessages.blockUserConfirmation
        })
    }

    const triggerRemoveParticipant = () => {
        triggerDialog({
            title: 'Remove participant',
            action: 'removeParticipant',
            okCallback: proceedWithConversationAction,
            content: appMessages.removeParticipantConfirmation
        })
    }

    const isUserUnavailable = () => {
        return participant.isBlocked || participant.status === 'unavailable'
    }

    const getMenuOptions = () => {
        let menuOptions = []

        if (!isUserUnavailable()) {
            menuOptions = [
                {
                    callback: triggerBlockParticipant,
                    className: 'important',
                    name: 'Block'
                }
            ]
        }

        if (isUserConversationAdmin(me) && !isUserConversationOwner(participant)) {
            menuOptions = [{
                callback: triggerRemoveParticipant,
                className: 'important',
                name: 'Remove'
            }].concat(menuOptions)

            if (!isUserUnavailable()) {
                menuOptions = [{
                    callback: changeParticipantRole,
                    className: isUserConversationAdmin(participant) ? 'important' : '',
                    name: isUserConversationAdmin(participant) ? 'Dismiss as Admin' : 'Make Admin'
                }].concat(menuOptions)
            }
        }

        return menuOptions
    }

    const canTakeAction = () => {
        return !isMe(participant)
    }

    const handleMenuClose = () => {
        setIsHovered(false)
        setIsOptionsOpened(false)
    }

    const handleParticipantClick = (event) => {
        setAnchorEl(event.currentTarget)
        setIsOptionsOpened(true)
    }

    const canHover = () => {
        if (participant.isBlocked && (!isUserConversationAdmin(me) || isUserConversationOwner(participant))) {
            return false
        }

        return isHovered && getMenuOptions().length > 0
    }

    const getParticipantLabel = () => {
        if (participant.isBlocked) {
            return 'Blocked'
        }
        if (isUserConversationAdmin(participant)) {
            return participant.role
        }
        if (participant.status === 'pending') {
            return 'Invitation sent'
        }
    }

    const shouldShowMobileMenu = () => isMobileDevice && canTakeAction() && getMenuOptions().length > 0

    const handleMouseEnter = () => canTakeAction() && !isMobileDevice && setIsHovered(true)
    const handleMouseEnterLeave = () => canTakeAction() && !isMobileDevice && setIsHovered(false)

    return <Fragment>
        {/* Temporarily disabled due to indefinite issues with the list scrolling and show of popups */}
        {/* <TouchableElement
            canTakeAction={canTakeAction}
            setIsHovered={setIsHovered}
            handleClick={handleParticipantClick}
            isListScrolling={isListScrolling}
        > */}
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseEnterLeave}
        >
            <StyledListItem button
                className='participant-style'
                participant={participant}
                disabled={isUserUnavailable()}
                data-testid='participant-item'
            >
                <ListItemAvatar data-testid='participant-photo'>
                    <UserAvatar user={participant} />
                </ListItemAvatar>
                <ListItemText
                    primary={participant.name}
                    data-testid='participant-name'
                />
                {canHover() ?
                    <StyledListItemSecondaryAction
                        theme={theme}
                        showsmobilemenu={shouldShowMobileMenu().toString()}
                        cantakeaction={canTakeAction().toString()}
                    >
                        <StyledExpandMore
                            id='expand-more'
                            onClick={handleParticipantClick} data-testid='expand-participant-options'
                        />
                    </StyledListItemSecondaryAction> :
                    <StyledListItemSecondaryAction
                        onClick={ev => shouldShowMobileMenu() && handleParticipantClick(ev)}
                        theme={theme}
                        showsmobilemenu={shouldShowMobileMenu().toString()}
                        className={participant.isBlocked ? 'important' : ''}
                        data-testid='participant-role'
                    >
                        <StyledTypography>{getParticipantLabel()}</StyledTypography>
                        {shouldShowMobileMenu() && <StyledMoreVertIcon theme={theme} />}
                    </StyledListItemSecondaryAction>
                }
            </StyledListItem>
        </div>
        {/* </TouchableElement> */}
        <ItemActionsMenu
            options={getMenuOptions()}
            isOpened={isOptionsOpened}
            anchorEl={anchorEl}
            onClose={handleMenuClose}
        />
        <Divider />
        <ConfirmationDialog
            isOpened={isDialogOpened}
            setIsOpened={setIsDialogOpened}
            settings={dialogSettings}
        >{dialogSettings.content}</ConfirmationDialog>
    </Fragment>
}

const StyledListItem = styled(ListItem)`
    && {
        font-style: ${props => props.disabled ? 'italic' : 'normal'};
        padding-right: ${props => props.participant.status === 'pending' ? '88px' : '48px'};
        word-break: break-all;
    }
`

const StyledExpandMore = styled(ExpandMore)`
    color: #c4c4c4;
    cursor: pointer;
`

const StyledTypography = styled(Typography)`
    && {
        font-size: 10px;
    }
`
const StyledMoreVertIcon = styled(MoreVertIcon)`
    color: ${props => props.theme.palette.grey['500']};
`

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
    && {
        align-items: center;
        display: flex;
        right: ${props => props.showsmobilemenu === 'true' ? '0px' : '8px'};        
        text-transform: capitalize;
        
        ${StyledTypography} {
            color: ${props => props.theme.palette.secondary.main};
            right: 8px;
        }

        &.important {
            ${StyledTypography} {
                color: #e76d65;
            }
        }
    }
`

ConversationParticipant.propTypes = {
    participant: PropTypes.object,
    conversation: PropTypes.object,
    me: PropTypes.object,
    theme: PropTypes.object,
    isListScrolling: PropTypes.func,
    canShowActions: PropTypes.func
}

ConversationParticipant.defaultProps = {
    theme: {
        palette: {}
    }
}

export default withTheme(ConversationParticipant)
