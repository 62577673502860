import React, { Fragment, useEffect, useContext, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import Header from '../common/Header'
import Search from '../common/Search'
import ContactsList from './ContactsList'
import NewConversation from './NewConversation'
import { AppGlobalContext } from '../../managers/AppManager'
import { ContactsContext } from '../../managers/ContactsManager'
import { ConversationsContext } from '../../managers/ConversationsManager'
import useConversations from '../../hooks/conversationsHook'
import useContacts from '../../hooks/contactsHook'
import Divider from '@material-ui/core/Divider'
import tools from '../../utils/tools'
import LoadingLayer from '../common/LoadingLayer'

const ConversationContactsView = ({ history, setIsAddingParticipants }) => {
    const [removingUsersQueue, setRemovingUsersQueue] = useState([])
    const [viewSettings, setViewSettings] = useState({})
    const [isConversationUpdated, setIsConversationUpdated] = useState(true)

    const { loggedUser } = useContext(AppGlobalContext)
    const { contactsView, openedConversation, setOpenedConversation,
        refreshConversationsList, sortParticipantsList } = useContext(ConversationsContext)
    const { searchString, setSearchString, isSearching, contacts,
        setContacts, refreshContacts, isRefreshInProgress,
        resetContacts, setIsEmailSearching } = useContext(ContactsContext)
    const { newConversationUsers, setNewConversationUsers,
        getConversationParticipants, createConversation, addParticipants } = useConversations()
    const { inviteContactToAllcal } = useContacts()
    const refContacts = useRef(null)
    const refNewConversationUsers = useRef(null)

    const searchContactHandler = async (event) => {

        if (event.target.value.length < 4) {
            return
        }
        event.persist()

        const searchedContact = _.trim(event.target.value.toLowerCase())
        setIsEmailSearching(tools.isValidEmail(searchedContact))

        await refreshContacts(refNewConversationUsers.current, searchedContact, true)

        if (_.isEmpty(refContacts.current) && tools.isValidEmail(searchedContact) &&
            loggedUser.email !== searchedContact) {
            setContacts([{
                userId: 'nonAllcalUser',
                isBlocked: false,
                name: searchedContact,
                email: searchedContact
            }])
        }
    }

    const searchContact = (event) => {
        setSearchString(event.target.value)
        searchContactHandler(event)
    }

    const addContactAsParticipant = (contact) => {
        let usersClone = refNewConversationUsers.current.slice(0)
        if (_.find(usersClone, user => user.userId === contact.userId)) {
            return
        }
        usersClone = usersClone.concat([contact])
        setNewConversationUsers(usersClone)
        resetContacts()
    }

    const selectContact = async (contact) => {
        let selectedContact = contact

        if (contact.userId === 'nonAllcalUser') {
            const invitedUser = await inviteContactToAllcal(contact)

            if (!invitedUser.error) {
                selectedContact = { userId: invitedUser.userId, name: invitedUser.linkedEntity.email }
            }
        }

        setContacts([])
        setSearchString('')
        addContactAsParticipant(selectedContact)
    }

    const removeUser = async (user) => {
        let usersClone = refNewConversationUsers.current.slice(0)
        _.remove(usersClone, p => p.userId === user.userId)
        setNewConversationUsers(usersClone)

        if (isRefreshInProgress) {
            setRemovingUsersQueue(prevState => {
                let newState = prevState.slice(0)
                newState.push(user)
                return newState
            })
            return
        }

        await refreshContacts(usersClone, searchString, true)

        setRemovingUsersQueue(prevState => {
            let newState = prevState.slice(0)
            newState.shift()
            return newState
        })
    }

    const isInvalidNewConversation = () => {
        return refNewConversationUsers.current.length === 1 && refNewConversationUsers.current[0].isBlocked
    }

    const initiateConversation = async () => {
        if (isInvalidNewConversation()) {
            return
        }

        setIsConversationUpdated(false)

        const newConversationInfo = {
            type: 'group',
            photo: '',
            description: '',
            users: _.map(refNewConversationUsers.current, 'userId')
        }

        const createdConversation = await createConversation(newConversationInfo)
        setNewConversationUsers([])
        let participants = await getConversationParticipants(createdConversation.conversationId)
        if (participants.error) {
            participants = []
        }

        setOpenedConversation({ ...createdConversation, participants: sortParticipantsList(participants) })
        setIsConversationUpdated(true)
        history.push(`/conversations/${createdConversation.conversationId}`)
        refreshContacts([], '')
        refreshConversationsList('', true)
    }

    const addParticipantsToConversation = async () => {
        setIsConversationUpdated(false)
        const newParticipants = _.map(refNewConversationUsers.current, 'userId')
        await addParticipants(openedConversation.conversationId, { users: newParticipants })

        setNewConversationUsers([])
        setIsAddingParticipants(false)
        setIsConversationUpdated(true)
    }

    const getCustomViewSettings = () => {
        switch (contactsView) {
            case 'newConversation':
                return {
                    viewName: 'Start New Conversation',
                    selectedCollectionName: 'Chat with',
                    link: '/conversations',
                    headerClickHandler: () => { },
                    confirmationClickHandler: initiateConversation
                }
            case 'addParticipants':
                return {
                    viewName: 'Add New Participants',
                    selectedCollectionName: 'New Participants',
                    link: '',
                    headerClickHandler: () => setIsAddingParticipants(false),
                    confirmationClickHandler: addParticipantsToConversation
                }
            default:
                return
        }
    }

    useEffect(() => {
        refContacts.current = contacts
    }, [contacts])

    useEffect(() => {
        refNewConversationUsers.current = newConversationUsers
    }, [newConversationUsers])

    useEffect(() => {
        setViewSettings(getCustomViewSettings())
    }, [contactsView])

    useEffect(() => {
        if (!isRefreshInProgress && !_.isEmpty(removingUsersQueue)) {
            removeUser(removingUsersQueue[0])
        }
    }, [isRefreshInProgress])

    return (
        <StyledInitConversationView data-testid='init-conversation-view'>
            <Header
                hasIcon
                iconAction='close'
                size='h6'
                text={viewSettings.viewName}
                link={viewSettings.link}
                clickHandler={viewSettings.headerClickHandler}
            />
            {newConversationUsers.length > 0 &&
                <Fragment>
                    <NewConversation
                        removeUser={removeUser}
                        users={newConversationUsers}
                        activeView={contactsView}
                        viewSettings={{
                            ...viewSettings,
                            confirmationButtonText: contactsView === 'newConversation' ?
                                'Start Conversation' : `Add Selected (${newConversationUsers.length})`
                        }}
                    />
                    <Divider />
                </Fragment>
            }
            <Search handleInputChange={searchContact} isSearching={isSearching}
                placeholderText={'Type in a name or an email address'} searchString={searchString} />
            <ContactsList
                activeView={contactsView}
                name='CONTACTS (A to Z)'
                selectContact={selectContact}
                selectedUsers={newConversationUsers}
            />
            {!isConversationUpdated && <LoadingLayer />}
        </StyledInitConversationView>
    )
}

const StyledInitConversationView = styled.div`
    height: 100%;
    position: relative;
`

ConversationContactsView.propTypes = {
    history: PropTypes.object,
    viewSettings: PropTypes.object,
    setIsAddingParticipants: PropTypes.func
}

export default withRouter(ConversationContactsView)
