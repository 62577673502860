import React, { useContext, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import styled from 'styled-components'
import Header from '../common/Header'
import ConversationInfoOptions from './ConversationInfoOptions'
import ConversationParticipants from './ConversationParticipants'
import { ConversationsContext } from '../../managers/ConversationsManager'
import { AppGlobalContext } from '../../managers/AppManager'
import useWindowSize from '../../hooks/windowSizeHook'
import useConversations from '../../hooks/conversationsHook'
import Slide from '@material-ui/core/Slide'
import ContactsManager from '../../managers/ContactsManager'
import ConversationContactsView from '../SideBar/ConversationContactsView'
import ConversationGroupInfo from './ConversationGroupInfo'
import LoadingLayer from '../common/LoadingLayer'

const ConversationsDetailsView = ({ history }) => {
    const [isInEditMode, setIsInEditMode] = useState(false)
    const [editedConversation, setEditedConversation] = useState({})
    const [isNameValid, setIsNameValid] = useState(true)
    const [me, setMe] = useState({})
    const [isAddingParticipants, setIsAddingParticipants] = useState(false)

    const { openedConversation,
        refreshConversationParticipantsList, openConversation, closeConversation } = useContext(ConversationsContext)
    const { userChannel, loggedUser, areHeadersSet } = useContext(AppGlobalContext)
    const { listMaxHeight } = useWindowSize(56)
    const { isUserConversationAdmin, getConversationById, editConversation } = useConversations()
    const refOpenedConversation = useRef(openedConversation)

    const toggleEditMode = async () => {
        if (!isNameValid) {
            return
        }

        if (isInEditMode && !_.isEmpty(editedConversation)) {
            await editConversation(openedConversation.conversationId, editedConversation)
        }

        setIsInEditMode(prevState => !prevState)
        setIsNameValid(true)
    }

    const getTooltipText = () => {
        if (!isUserConversationAdmin(me)) {
            return 'Only admins can edit group information'
        }

        if (!isNameValid) {
            return 'Group name is mandatory'
        }
    }

    const handleConversationDeleted = (data) => {
        if (data.conversationId !== openedConversation.conversationId) {
            return
        }

        closeConversation()
    }

    const handleUsersEvent = (data) => {
        switch (data.type) {
            case 'updated_in_conversation':
            case 'joined':
                if (data.conversationId !== openedConversation.conversationId) {
                    return
                }
                openConversation(data.conversationId)
                refreshConversationParticipantsList(data.conversationId, true, 0, data.at)
                return
            case 'left':
                if (data.conversationId !== openedConversation.conversationId) {
                    return
                }
                getConversationById(data.conversationId)
                    .then(() => {
                        openConversation(data.conversationId)
                        refreshConversationParticipantsList(data.conversationId, true, 0, data.at)
                    })
                    .catch(closeConversation)
                return
            case 'blocked':
                refreshConversationParticipantsList(openedConversation.conversationId, true, 0, data.at)
                return
            default:
                return
        }
    }

    const handleConversationsEvent = (data) => {
        if (data.conversationId !== refOpenedConversation.current.conversationId) {
            return
        }

        switch (data.type) {
            case 'hidden':
            case 'deleted':
                handleConversationDeleted(data)
                return
            case 'updated':
                openConversation(data.conversationId)
                return
            default:
                return
        }
    }

    const canViewDetails = () => {
        return !_.isEmpty(openedConversation) && openedConversation.status !== 'unavailable'
    }

    useEffect(() => {
        refOpenedConversation.current = openedConversation
        if (openedConversation.status === 'unavailable') {
            history.push(`/conversations/${openedConversation.conversationId}`)
        }
    }, [openedConversation])

    useEffect(() => {
        if (_.isEmpty(loggedUser) || _.isEmpty(openedConversation)) {
            return
        }

        setMe(_.find(refOpenedConversation.current.participants,
            participant => participant.userId === loggedUser.userId))
    }, [loggedUser, openedConversation])

    useEffect(() => {
        if (_.isEmpty(userChannel)) {
            return
        }

        userChannel.bind('users', handleUsersEvent)
        userChannel.bind('conversations', handleConversationsEvent)
    }, [userChannel])

    return <StyledDetailsViewWrapper>
        {isAddingParticipants ?
            <Slide direction='up' in>
                <div className='full-height'>
                    <ContactsManager>
                        <ConversationContactsView setIsAddingParticipants={setIsAddingParticipants} />
                    </ContactsManager>
                </div>
            </Slide>
            :
            (canViewDetails() ?
                <StyledConversationDetailsWrapper
                    className='full-height' data-testid='conversation-details-wrapper'>
                    <Header
                        hasIcon
                        iconAction='close'
                        size='body1'
                        text='Group Info'
                        link={`/conversations/${openedConversation.conversationId}`}
                        hasActionButton
                        hasActionRights={isUserConversationAdmin(me) && isNameValid}
                        isInEditMode={isInEditMode}
                        tooltipDisabledText={getTooltipText()}
                        actionButtonCallback={toggleEditMode}
                    />
                    <StyledConversationDetailsContent listmaxheight={listMaxHeight}>
                        <ConversationGroupInfo
                            isNameValid={isNameValid}
                            setIsNameValid={setIsNameValid}
                            isInEditMode={isInEditMode}
                            editedConversation={editedConversation}
                            setEditedConversation={setEditedConversation}
                        />
                        <ConversationParticipants
                            isUserAdmin={isUserConversationAdmin(me)}
                            me={me}
                            setIsAddingParticipants={setIsAddingParticipants}
                            users={openedConversation.participants}
                        />
                        <ConversationInfoOptions me={me} conversation={openedConversation} />
                    </StyledConversationDetailsContent>
                </StyledConversationDetailsWrapper> :
                (areHeadersSet && <LoadingLayer />))
        }
    </StyledDetailsViewWrapper>
}

ConversationsDetailsView.propTypes = {
    history: PropTypes.object
}

const StyledDetailsViewWrapper = styled.div`
    height: 100%;
    position: relative;
`

const StyledConversationDetailsContent = styled.div`
    display: flex;
    flex-direction: column;
    max-height: ${props => props.listmaxheight}px;
    overflow-y: auto;
`

const StyledConversationDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`

export default withRouter(ConversationsDetailsView)