import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { withTheme } from '@material-ui/styles'
import InputBase from '@material-ui/core/InputBase'
import LinearProgress from '@material-ui/core/LinearProgress'
import { CreateMessageContext } from '../../managers/CreateMessageManager'
import MediaAttachment from '../common/MediaAttachment'
import CreateMessageActions from '../ConversationWindow/CreateMessageActions'

const CreateMessage = ({ setSiblingHeight, isMobileView, theme }) => {
    const { uploadProgress, messageObject, setMessageObject,
        isMediaLoading, handleSendMessage } = useContext(CreateMessageContext)

    const handleInputChange = (event) => {
        setMessageObject({ ...messageObject, message: event.target.value })
    }

    const handleKeyPress = (event) => {
        event.key === 'Enter' && handleSendMessage()
    }

    useEffect(() => {
        if (!_.isEmpty(messageObject.media)) {
            const attachmentsHaveVideo = !!_.find(messageObject.media, att => att.indexOf('videos') !== -1)
            setSiblingHeight(attachmentsHaveVideo ? 408 : 248)
        } else {
            setSiblingHeight(132)
        }
    }, [messageObject])

    return (
        <StyledCreateMessageWrapper data-testid='create-message-wrapper'>
            {isMediaLoading && <StyledLinearProgress
                color='secondary'
                variant='determinate'
                value={uploadProgress} />
            }
            {!_.isEmpty(messageObject.media) &&
                <StyledNewMessageAttachments>
                    {_.map(messageObject.media, (file, idx) =>
                        <MediaAttachment key={idx} idx={idx} attachment={{ url: file }} />
                    )}
                </StyledNewMessageAttachments>
            }
            <StyledNewMessageTextSection>
                <StyledInputWrapper data-testid='new-message-input-wrapper'>
                    <StyledInputBase
                        theme={theme}
                        disabled={isMediaLoading}
                        placeholder='Type a message'
                        value={messageObject.message}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        data-testid='new-message-input'
                    />
                </StyledInputWrapper>
                <CreateMessageActions isMobileView={isMobileView} />
            </StyledNewMessageTextSection>
        </StyledCreateMessageWrapper>
    )
}

const StyledNewMessageAttachments = styled.div`
    margin-bottom: 8px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
`

const StyledCreateMessageWrapper = styled.div`
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    padding: 16px;
    position: relative;
`

const StyledLinearProgress = styled(LinearProgress)`
    && {
        height: 4px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
`

const StyledNewMessageTextSection = styled.div`
    display: flex;
    flex-direction: row;
`

const StyledInputWrapper = styled.div`
    flex-grow: 1;
`

const StyledInputBase = styled(InputBase)`
    color: inherit;
    width: 100%;
    input {
        border-radius: ${props => props.theme.shape.borderRadius}px;
        color: ${props => props.theme.palette.grey['800']};
        line-height: initial;
        padding: 8px;
        width: 100%;
        &, &:hover {
            background-color: #ffffff;
        }
    }
`

CreateMessage.propTypes = {
    conversation: PropTypes.object,
    setSiblingHeight: PropTypes.func,
    isMobileView: PropTypes.bool,
    theme: PropTypes.object
}

CreateMessage.defaultProps = {
    theme: {
        shape: {},
        palette: {},
        spacing: {}
    }
}

export default withTheme(CreateMessage)
