import moment from 'moment'

const formatDate = (date, showHumanFormat) => {
    if (showHumanFormat) {
        return moment(date).calendar(null, { sameDay: '[Today]', lastDay: '[Yesterday]', lastWeek: 'll', sameElse: 'll' })
    }
    return moment(date).calendar(null, { sameDay: 'LT', lastDay: 'LT', lastWeek: 'LT', sameElse: 'LT' })
}

export default formatDate
