import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = ({ children, to }) => {
    return <SLink activeClassName={'active'} activeStyle={{ backgroundColor: '#EBEBEB' }} exact to={to}>
        {children}
    </SLink>
}

const SLink = styled(NavLink)`
    all: unset;
    display: block;
`

StyledLink.propTypes = {
    children: PropTypes.object,
    isActive: PropTypes.bool,
    to: PropTypes.string
}

StyledLink.defaultProps = {
    isActive: false,
    to: '/conversations'
}

export default StyledLink