import React from 'react'
import PropTypes from 'prop-types'
import ConfirmationDialog from './ConfirmationDialog'
import ImageCropper from './ImageCropper'

const ImageCropperDialog = ({ imageFileRef, isDialogOpened, setIsDialogOpened,
    dialogSettings, selectedImage, setNewImage }) => {

    return <ConfirmationDialog
        isOpened={isDialogOpened}
        setIsOpened={setIsDialogOpened}
        settings={dialogSettings}
    >
        <ImageCropper
            selectedImage={selectedImage}
            setCroppedImage={setNewImage}
            imageFileRef={imageFileRef}
        />
    </ConfirmationDialog>
}

ImageCropperDialog.propTypes = {
    imageFileRef: PropTypes.object,
    isDialogOpened: PropTypes.bool,
    setIsDialogOpened: PropTypes.func,
    dialogSettings: PropTypes.object,
    selectedImage: PropTypes.any,
    setNewImage: PropTypes.func
}

export default ImageCropperDialog