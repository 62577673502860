import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTheme } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import Icon from '@material-ui/core/Icon'
import Badge from '@material-ui/core/Badge'
import ReactPlayer from 'react-player'
import appMessages from '../../utils/appMessages'
import ReactMarkdown from 'react-markdown'

const MessageBubbleAttachment = ({ theme, message, openPhoto }) => {
    const [isAttachmentValid, setIsAttachmentValid] = useState(true)
    const attachmentType = message.media.indexOf('photos') !== -1 ? 'image' : 'video'

    const displayMediaError = () => {
        setIsAttachmentValid(false)
    }

    const getAttachment = (type) => {
        switch (type) {
            case 'image':
                return <StyledMessageImage onClick={openPhoto} onError={displayMediaError}
                    src={message.media} data-testid='message-attachment-photo' />
            case 'video':
                return <StyledReactPlayer controls height='unset' width='unset' url={message.media} onError={displayMediaError} data-testid='message-attachment-video' />
            default:
                return <StyledMessageImage onClick={openPhoto} onError={displayMediaError} src={message.media} data-testid='message-attachment-photo' />
        }
    }

    return <StyledBubbleAttachment theme={theme} data-testid='message-media'>
        {isAttachmentValid ? getAttachment(attachmentType) :
            <StyledBubbleTypography theme={theme}
                data-testid='message-attachment-error'>
                {appMessages.invalidAttachment}
            </StyledBubbleTypography>}
    </StyledBubbleAttachment>
}

const MessageCore = ({ theme, isMine, message }) => {
    const [isPhotoOpened, setIsPhotoOpened] = useState(false)

    const openPhoto = () => {
        setIsPhotoOpened(true)
    }

    const closePhoto = () => {
        setIsPhotoOpened(false)
    }

    const getMarkdownMessage = (message) => {
        return <ReactMarkdown source={message} linkTarget='_blank' />
    }

    return <StyledMessageBubble theme={theme} className={isMine && 'my-message'} data-testid='message-content'>
        {message.media && <MessageBubbleAttachment theme={theme} message={message} openPhoto={openPhoto} closePhoto={closePhoto} />}
        <StyledBubbleText theme={theme} data-testid='message-text'>{getMarkdownMessage(message.message)}</StyledBubbleText>
        <Dialog open={isPhotoOpened} onClose={closePhoto}>
            <StyledBadge
                color='primary'
                badgeContent={<StyledBadgeIcon onClick={closePhoto}>close</StyledBadgeIcon>}
            ><span></span></StyledBadge>
            <StyledPhotoZoom alt='media-attachment' src={message.media} />
        </Dialog>
    </StyledMessageBubble>
}

const StyledBadge = styled(Badge)`
    position: absolute;
    right: 0;
    && > span {
        span {
            cursor: pointer;
        }
        right: 16px;
        top: 16px;
    }
`

const StyledBadgeIcon = styled(Icon)`
    && {
        font-size: 12px;
    }
`

const StyledMessageImage = styled.img`
    border-radius: 4px;
    max-width: 160px;
`

const StyledReactPlayer = styled(ReactPlayer)`
    video {
        // Yes, I am ashamed of this.
        width: unset !important;
        height: unset !important;
        max-width: 100%;
        max-height: 240px;
    }
`

const StyledBubbleTypography = styled(Typography)`
    color: ${props => props.theme.palette.primary.main};
`

const StyledBubbleText = styled.div`
    color: ${props => props.theme.palette.primary.main};
    p, h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }
    a:-webkit-any-link {
        color: ${props => props.theme.palette.secondary.main};
    }
`

const StyledMessageBubble = styled.div`
    && {
        background-color: #efefef;
        border-radius: ${props => props.theme.shape.borderRadius}px;
        padding: 8px;

        &.my-message {
            background-color: ${props => props.theme.palette.primary.main};

            ${StyledBubbleTypography}, ${StyledBubbleText} {
                color: #ffffff;
            }

            ${StyledBubbleText} a:-webkit-any-link {
                color: ${props => props.theme.palette.secondary.main};
            }
        }
    }
`

const StyledPhotoZoom = styled.img`
    max-height: -webkit-fill-available;
    max-width: 100%;
`

const StyledBubbleAttachment = styled.div`
    align-items: center;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

MessageBubbleAttachment.propTypes = {
    message: PropTypes.object,
    openPhoto: PropTypes.func,
    theme: PropTypes.object
}

MessageBubbleAttachment.defaultProps = {
    theme: {
        palette: {},
        shape: {}
    }
}

MessageCore.propTypes = {
    message: PropTypes.object,
    isMine: PropTypes.bool,
    theme: PropTypes.object
}

MessageCore.defaultProps = {
    theme: {
        palette: {},
        shape: {}
    }
}

MessageCore.whyDidYouRender = true
MessageBubbleAttachment.whyDidYouRender = true
export default withTheme(memo(MessageCore))