import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'
import { AppGlobalContext } from '../../managers/AppManager'

const ParticipantsList = ({ users, removeUser }) => {
    const { loggedUser } = useContext(AppGlobalContext)
    const isLoggedUser = user => user.userId === loggedUser.userId

    return <StyledParticipantsListWrapper data-testid='participants-list'>
        {users.map(user => <StyledChip
            key={user.userId}
            label={user.name}
            onDelete={isLoggedUser(user) ? null : () => removeUser(user)}
            data-testid='user-chip'
        />)}
    </StyledParticipantsListWrapper>
}

const StyledParticipantsListWrapper = styled.div`
    display: flex;
    height: 50px;
    margin-top: 8px;
    overflow: auto;
`

const StyledChip = styled(Chip)`
    margin-right: 8px;
`

ParticipantsList.propTypes = {
    removeUser: PropTypes.func,
    users: PropTypes.arrayOf(PropTypes.object)
}

export default ParticipantsList