import { useState, useEffect } from 'react'

const useSiblingWidth = () => {
    const [maxWidth, setMaxWidth] = useState(100)
    const [sibling, setSibling] = useState(null)

    useEffect(() => {
        if (!sibling) {
            return
        }

        // -1 is for some resolutions that refuse to display the whole content
        setMaxWidth(window.innerWidth - sibling.offsetWidth - 1)
        const listener = () => {
            setMaxWidth(window.innerWidth - sibling.offsetWidth)
        }

        window.addEventListener('resize', listener)
        return () => {
            window.removeEventListener('resize', listener)
        }
    }, [sibling])

    return { maxWidth, setSibling }
}

export default useSiblingWidth
