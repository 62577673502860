import { useState, useContext } from 'react'
import API from '../server'
import _ from 'lodash'
import { ConversationsContext } from '../managers/ConversationsManager'

const itemsPerPage = 20

const getConversationMuteState = async (conversationId) => {
    const conversationStatus = await API.get(`/conversations/${conversationId}/mute`)
    return conversationStatus.data
}

const getConversationById = async (conversationId) => {
    const conversationResponse = await API.get(`/conversations/${conversationId}`)
    return conversationResponse.data
}

const getConversationParticipants = async (conversationId, skip = 0, at) => {
    const participantsResponse = await API.get(`/conversations/${conversationId}/users`, { skip, limit: itemsPerPage, from: at })
    return participantsResponse.data
}

const getConversations = async (searchString = '', skip = 0, at) => {
    const conversationsResponse = await API.get('/conversations',
        { q: searchString, skip, limit: itemsPerPage, from: at })
    return conversationsResponse.data
}

const createConversation = async (conversationInfo) => {
    const conversationResponse = await API.post('/conversations', conversationInfo)
    return conversationResponse.data
}

const editConversation = async (conversationId, updatedData) => {
    const conversationResponse = await API.put(`/conversations/${conversationId}`, updatedData)
    return conversationResponse.data
}

const deleteConversation = async (conversationId) => {
    //for destroying the conv sent {hard: true} as param
    const conversationResponse = await API.delete(`/conversations/${conversationId}`)
    return conversationResponse.data
}

const muteGroup = async (conversationId) => {
    const conversationResponse = await API.post(`/conversations/${conversationId}/mute`)
    return conversationResponse.data
}

const unmuteGroup = async (conversationId) => {
    const conversationResponse = await API.delete(`/conversations/${conversationId}/mute`)
    return conversationResponse.data
}

const addParticipants = async (conversationId, newParticipants) => {
    const participantsResponse = await API.post(`/conversations/${conversationId}/users`, newParticipants)
    return participantsResponse.data
}

const changeParticipantRole = async (participantId, conversationId, role) => {
    const participantsResponse = await API.put(`/conversations/${conversationId}/users/${participantId}`, { role })
    return participantsResponse.data
}

const removeParticipant = async (conversationId, userId) => {
    const userResponse = await API.delete(`/conversations/${conversationId}/users/${userId}`)
    return userResponse.data
}

const isUserConversationAdmin = (user) => _.includes(['admin', 'owner'], user.role)
const isUserConversationOwner = (user) => user.role === 'owner'

const useConversations = () => {
    const [newConversationUsers, setNewConversationUsers] = useState([])
    const { openedConversation } = useContext(ConversationsContext)

    const changeRole = (participant) => {
        const newRole = isUserConversationAdmin(participant) ? 'participant' : 'admin'
        changeParticipantRole(participant.userId, openedConversation.conversationId, newRole)
    }

    return {
        itemsPerPage,
        getConversations,
        createConversation,
        editConversation,
        deleteConversation,
        muteGroup,
        unmuteGroup,
        getConversationById,
        newConversationUsers,
        setNewConversationUsers,
        getConversationParticipants,
        changeParticipantRole,
        isUserConversationAdmin,
        isUserConversationOwner,
        getConversationMuteState,
        changeRole,
        addParticipants,
        removeParticipant
    }
}

export default useConversations