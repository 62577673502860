import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'

const ConversationTitle = ({ conversation }) => {
    return <StyledPartWrapper data-testid='conversation-primary-text'>
        {conversation.type === 'calendar' && <StyledChip
            label={conversation.type}
            chipcolor={conversation.preferences.color}
            data-testid='primary-text-chip'
        />}
        <StyledTypography component='span' conversation={conversation} variant='body1'
            data-testid='primary-text-name'>{conversation.name}</StyledTypography>
    </StyledPartWrapper>
}

const StyledPartWrapper = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const StyledChip = styled(Chip)`
    && {
        background-color: ${props => props.chipcolor};
        color: #FFFFFF;
        font-size: 0.7125rem;
        height: 20px;
        margin-right: 10px
        position: relative;
        text-transform: uppercase;
        top: 1px;
        vertical-align: text-top;
    }
`

const StyledTypography = styled(Typography)`
    && {
        display: inline;
        font-weight: ${props => props.conversation.unreadCount > 0 ? '600' : '400'};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

ConversationTitle.propTypes = {
    conversation: PropTypes.object
}

export default ConversationTitle
