import React, { Fragment, useState, useEffect, useRef, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import appMessages from '../../utils/appMessages'
import { AppGlobalContext } from '../../managers/AppManager'
import { ConversationsContext } from '../../managers/ConversationsManager'
import Button from '@material-ui/core/Button'
import StyledSectionHeader from './StyledSectionHeader'
import StyledSectionContent from './StyledSectionContent'
import useConversations from '../../hooks/conversationsHook'
import useConfirmationDialog from '../../hooks/confirmationDialogHook'
import ConfirmationDialog from '../common/ConfirmationDialog'
import { allcalAppUrl } from '../../server'

const ConversationInfoOptions = ({ conversation, me, history }) => {
    const [isMuted, setIsMuted] = useState(false)
    const [options, setOptions] = useState([])

    const { setOpenedConversation } = useContext(ConversationsContext)
    const { userChannel } = useContext(AppGlobalContext)
    const { isUserConversationOwner, muteGroup, unmuteGroup,
        getConversationMuteState, removeParticipant } = useConversations()
    const { triggerDialog, isDialogOpened, dialogSettings, setIsDialogOpened } = useConfirmationDialog()
    let meRef = useRef(null)
    let refIsMuted = useRef(null)

    const toggleConversationStatus = () => {
        const toggleStatusFunction = refIsMuted.current ? unmuteGroup : muteGroup
        setIsMuted(prevState => !prevState)
        toggleStatusFunction(conversation.conversationId)
    }

    const proceedWithConversationAction = (action) => {
        switch (action) {
            case 'leaveConversation':
                removeParticipant(conversation.conversationId, meRef.current.userId)
                history.push('/conversations')
                setOpenedConversation({})
                window.parent.postMessage({}, allcalAppUrl)
                break
            default:
                break
        }
    }

    const triggerLeaveConversation = () => {
        triggerDialog({
            title: 'Leave Conversation',
            action: 'leaveConversation',
            okCallback: proceedWithConversationAction,
            content: appMessages.leaveConversationConfirmation
        })
    }

    const setupConversationStatus = async () => {
        const conversationStatus = await getConversationMuteState(conversation.conversationId)
        await setIsMuted(conversationStatus.muted)
    }

    const setupConversationOptions = (data) => {
        if (data) {
            setIsMuted(data.type === 'muted')
        }

        let conversationOptions = [
            {
                id: 'mute',
                text: `${(data ? data.type === 'muted' : refIsMuted.current) ? 'Unmute' : 'Mute'} Group`,
                className: '', callback: toggleConversationStatus
            }
        ]

        if (!isUserConversationOwner(meRef.current)) {
            setOptions(conversationOptions.concat([{
                id: 'leave', text: 'Leave Group',
                className: 'important', callback: triggerLeaveConversation
            }]))
            return
        }

        setOptions(conversationOptions)
    }

    const handleConversationsEvent = (data) => {
        if (data.conversationId !== conversation.conversationId) {
            return
        }

        switch (data.type) {
            case 'muted':
            case 'unmuted':
                setupConversationOptions(data)
                return
            default:
                return
        }

    }

    useEffect(() => {
        meRef.current = me
        setupConversationStatus()
        setupConversationOptions()
    }, [me])

    useEffect(() => {
        refIsMuted.current = isMuted
    }, [isMuted])

    useEffect(() => {
        if (_.isEmpty(userChannel)) {
            return
        }

        userChannel.bind('conversations', handleConversationsEvent)
    }, [userChannel])

    return <Fragment>
        <StyledSectionHeader title='options' data-testid='options-section-header' />
        <StyledSectionContent data-testid='options-section-header'>
            <Fragment>
                {options.map(option => <StyledButton
                    key={option.id}
                    className={option.className}
                    onClick={option.callback}
                    disableRipple
                    disableFocusRipple
                    data-testid={`options-section-${option.id}`}
                >
                    {option.text}
                </StyledButton>)}
            </Fragment>
        </StyledSectionContent>
        <ConfirmationDialog
            isOpened={isDialogOpened}
            setIsOpened={setIsDialogOpened}
            settings={dialogSettings}
        >{dialogSettings.content}</ConfirmationDialog>
    </Fragment>
}

const StyledButton = styled(Button)`
    && {
        display: block;
        margin-left: 8px;
        text-transform: capitalize;
        
        &:first-child {
            margin-top: 8px;
        }
    }

    &&.important {
        span {
            color: #e76d65;
        }
    }
`

ConversationInfoOptions.propTypes = {
    conversation: PropTypes.object,
    me: PropTypes.object,
    history: PropTypes.object
}

export default withRouter(ConversationInfoOptions)