import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Badge from '@material-ui/core/Badge'
import Icon from '@material-ui/core/Icon'
import ReactPlayer from 'react-player'
import { CreateMessageContext } from '../../managers/CreateMessageManager'

const MediaItem = ({ attachment, type }) => {
    switch (type) {
        case 'image':
            return <img alt='message-attachment' src={attachment.url} data-testid='new-message-image' />
        case 'video':
            return <StyledReactPlayer controls width='unset' url={attachment.url} data-testid='new-message-video' />
        default:
            return <img alt='message-attachment' src={attachment.url} data-testid='new-message-image' />
    }
}

const MediaAttachment = ({ attachment, idx }) => {
    const attachmentType = attachment.url.indexOf('photos') !== -1 ? 'image' : 'video'
    const { removeAttachment } = useContext(CreateMessageContext)
    return <StyledMediaAttachmentWrapper>
        <StyledBadge
            color='primary'
            badgeContent={<StyledBadgeIcon onClick={() => removeAttachment(attachment.url, idx)}>close</StyledBadgeIcon>}
            type={attachmentType}
        >
            <StyledMediaWrapper type={attachmentType}>
                <MediaItem attachment={attachment} type={attachmentType} />
            </StyledMediaWrapper>
        </StyledBadge>
    </StyledMediaAttachmentWrapper>
}

const StyledMediaAttachmentWrapper = styled.div`
    display: inline-block;
    margin-bottom: 8px;
    margin-right: 24px;
    margin-top: 12px;
`

const StyledMediaWrapper = styled.div`
    border-radius: 4px;
    display: flex;
    height: ${props => props.type === 'image' ? '80' : '240'}px;
    margin-bottom: 8px;
    max-height: ${props => props.type === 'image' ? '80' : '240'}px;
    max-width: 160px;
    overflow: hidden;
    img {
        background-color: #ffffff;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
`

const StyledBadge = styled(Badge)`
    && > span {
        cursor: pointer;
    }
`

const StyledBadgeIcon = styled(Icon)`
    && {
        font-size: 12px;
    }
`

const StyledReactPlayer = styled(ReactPlayer)`
    && {
        border-radius: 4px;
        max-height: 100%;
        overflow: hidden;
        width: unset;

        video {
            background-color: #fff;
            height: 100%;
            max-height: 100%;
            max-width: 100%;
            // Yes, I am ashamed of this
            width: unset !important;
        }
    }
`

MediaItem.propTypes = {
    attachment: PropTypes.object,
    type: PropTypes.string
}

MediaAttachment.propTypes = {
    attachment: PropTypes.object,
    idx: PropTypes.number
}

MediaAttachment.defaultProps = {
    attachment: {},
    idx: 0
}

export default MediaAttachment