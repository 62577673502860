import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import StyledLink from '../common/StyledLink'
import Button from '@material-ui/core/Button'
import Edit from '@material-ui/icons/Edit'
import Check from '@material-ui/icons/Check'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import CustomTooltip from '../common/CustomTooltip'

const HeaderButton = ({ clickHandler, type }) => {
    return <StyledIconButton aria-label='Close' color='primary' onClick={clickHandler} data-testid='header-icon'>
        {type === 'close' ? <Icon>close</Icon> : <KeyboardArrowLeft />}
    </StyledIconButton>
}

const ActionButton = ({ isInEditMode, clickHandler, className }) => {
    return <StyledActionButton className={className} variant='outlined' size='small' color='primary'
        onClick={clickHandler}
        data-testid='header-action-btn'>
        {isInEditMode ? <StyledSpan><Check />Save</StyledSpan> : <StyledSpan><Edit />Edit</StyledSpan>}
    </StyledActionButton>
}

const Header = ({ clickHandler, hasIcon, iconAction, hasActionButton, hasActionRights, actionButtonCallback, tooltipDisabledText, link, size, text, isInEditMode }) => {

    return <StyledHeader data-testid='header-wrapper'>
        {hasIcon &&
            (link ?
                <StyledLink to={link}>
                    <HeaderButton clickHandler={clickHandler} type={iconAction} />
                </StyledLink> :
                <HeaderButton clickHandler={clickHandler} />
            )
        }
        <StyledHeaderText variant={size} noWrap data-testid='header-text'>
            {text}
        </StyledHeaderText>
        {hasActionButton &&
            (hasActionRights ?
                <ActionButton isInEditMode={isInEditMode} clickHandler={actionButtonCallback} />
                :
                <CustomTooltip tooltipText={tooltipDisabledText}>
                    <ActionButton className='disabled' isInEditMode={isInEditMode} />
                </CustomTooltip>
            )
        }
    </StyledHeader>
}

const StyledHeader = styled.div`
    align-items: center;
    align-self: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: -webkit-fill-available;
`

const StyledHeaderText = styled(Typography)`
    && {
        font-weight: 600;
        width: -webkit-fill-available;
    }
`

const StyledSpan = styled.span`
    align-items: center;
    display: flex;
    font-size: 12px;

    svg {
        font-size: 16px;
    }
`

const StyledIconButton = styled(IconButton)`
    && {
        padding: 0;
    }
`

const StyledActionButton = styled(Button)`
    && {
        padding: 0;
        text-transform: capitalize;

        &.disabled {
            opacity: 0.7;
        }
    }
`

HeaderButton.propTypes = {
    clickHandler: PropTypes.func,
    type: PropTypes.string
}

ActionButton.propTypes = {
    isInEditMode: PropTypes.bool,
    clickHandler: PropTypes.func,
    className: PropTypes.string
}

Header.propTypes = {
    clickHandler: PropTypes.func,
    hasIcon: PropTypes.bool.isRequired,
    iconAction: PropTypes.string,
    hasActionRights: PropTypes.bool.isRequired,
    hasActionButton: PropTypes.bool,
    actionButtonCallback: PropTypes.func,
    isInEditMode: PropTypes.bool,
    tooltipDisabledText: PropTypes.string,
    link: PropTypes.string,
    size: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
}

Header.defaultProps = {
    clickHandler: () => { },
    hasIcon: false,
    iconAction: '',
    hasActionRights: false,
    hasActionButton: false,
    actionButtonCallback: () => { },
    isInEditMode: false,
    tooltipDisabledText: '',
    link: '/conversations',
    size: 'h6',
    text: '',
}

export default Header