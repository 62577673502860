import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

const ListInfiniteScroll = ({ children, dataLength, height, hasMore, next, refreshFunction, endDelimiterHeight }) => {

    // set min height useful for mobile devices
    const listHeight = height >= 168 ? height : 168

    return <InfiniteScroll
        endMessage={
            <StyledEndDelimiter height={endDelimiterHeight} />
        }
        dataLength={dataLength}
        height={listHeight}
        hasMore={hasMore}
        next={next}
        loader={<StyledLoaderWrapper><CircularProgress color='secondary' /></StyledLoaderWrapper>}
        pullDownToRefresh
        pullDownToRefreshContent={
            <StyledPullToRefresh variant='caption'>&#8595; Pull down to refresh</StyledPullToRefresh>
        }
        refreshFunction={refreshFunction}
        releaseToRefreshContent={
            <StyledPullToRefresh variant='caption'>&#8593; Release to refresh</StyledPullToRefresh>
        }
    >
        {children}
    </InfiniteScroll>
}

const StyledLoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`

const StyledPullToRefresh = styled(Typography)`
    && {
        display: block;
        line-height: 30px;
        text-align: center;
    }
`

const StyledEndDelimiter = styled.div`
    margin: 0 auto;
    height: ${props => props.height}px;
    width: 30%;
`

ListInfiniteScroll.propTypes = {
    children: PropTypes.object,
    dataLength: PropTypes.number,
    height: PropTypes.number,
    hasMore: PropTypes.bool,
    next: PropTypes.func,
    endDelimiterHeight: PropTypes.number,
    refreshFunction: PropTypes.func
}

export default ListInfiniteScroll