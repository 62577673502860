import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DebounceInput } from 'react-debounce-input'
import SearchIcon from '@material-ui/icons/Search'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withTheme } from '@material-ui/styles'

const Search = ({ handleInputChange, isSearching, placeholderText, searchString, theme }) => {
    return <StyledSearchWrapper data-testid='search-wrapper'>
        {isSearching ?
            <StyledCircularProgress color='secondary' style={{ width: '20px', height: '20px' }} /> :
            <StyledSearchIcon color='disabled' data-testid='search-icon' />
        }
        <StyledDebounceInput
            value={searchString}
            theme={theme}
            debounceTimeout={1000}
            placeholder={placeholderText}
            onChange={event => handleInputChange(event)}
            data-testid='search-input'
        />
    </StyledSearchWrapper>
}

const StyledSearchWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 30px;
    padding: 16px;
    position: relative;
`

const StyledCircularProgress = styled(CircularProgress)`
    && {
        margin-left: 8px;
        position: absolute;
        z-index: 1;
    }
`

const StyledSearchIcon = styled(SearchIcon)`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 4px;
    pointer-events: none;
    position: absolute;
    z-index: 1;
`

const StyledDebounceInput = styled(DebounceInput)`
    border: none;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    color: ${props => props.theme.palette.grey['800']};
    font-size: 14px;
    padding: 8px;
    padding-left: 32px;
    width: 100%;

    &, &:hover {
        background-color: #efefef;
    }

    &:focus {
        outline: none;
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: ${props => props.theme.palette.grey['500']};
        font-size: 14px;
    }
`

Search.propTypes = {
    handleInputChange: PropTypes.func,
    isSearching: PropTypes.bool,
    placeholderText: PropTypes.string,
    searchString: PropTypes.string,
    theme: PropTypes.object
}

Search.defaultProps = {
    isSearching: false,
    placeholderText: 'Search',
    searchString: '',
    theme: {
        shape: {},
        palette: {},
        spacing: {}
    }
}

export default withTheme(Search)