export default {
    emptyConversationsListMessage: 'Looks like you haven\'t started any chats yet. All your conversations will show up here',
    groupCreatedMessage: 'created this group',
    noConversationsResultsMessage: 'No results',
    noContactsResultsMessage: 'Please type in an email address to invite to Chat.',
    contactsPlaceholder: 'Make sure you have at least 4 characters to see suggestions',
    invalidAttachment: 'This attachment is not available.',
    deleteConversationConfirmation: 'Are you sure you want to delete this conversation?',
    leaveConversationConfirmation: 'Are you sure you want to leave this conversation?',
    blockUserConfirmation: 'Are you sure you want to block this user?',
    removeParticipantConfirmation: 'Are you sure you want to remove this participant from the conversation?'
}