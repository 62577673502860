import _ from 'lodash'

/* eslint-disable */
const linkRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm
/* eslint-enable */

const getValidUrl = link => {
    const isValidUrl = _.startsWith(link, 'http://') || _.startsWith(link, 'https://')
    if (!isValidUrl) {
        return 'http://' + link
    }

    return link
}

export const getTextWithFormattedUrls = text => {
    const words = text.split(/(\S+\s+)/).filter(w => w)

    _.forEach(words, (word, index) => {
        const isLink = _.trim(word).match(linkRegex)
        if (isLink) {
            words.splice(index, 1, `[${word}](${getValidUrl(word)})`)
        }
    })
    return words.join(' ')
}
