import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import useCustomAppTheme from './utils/themeProvider'
import AppGrid from './components/AppGrid'
import './App.scss'

const App = () => {
  const { themeOptions } = useCustomAppTheme()

  return <ThemeProvider theme={createMuiTheme(themeOptions)}>
    <AppGrid />
  </ThemeProvider>
}

export default App
