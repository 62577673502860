import _ from 'lodash'
import moment from 'moment'

const groupMessagesBySender = (messages) => {
    let formattedCollection = []
    let previousSender = {}

    _.forEach(messages, message => {
        if (previousSender.userId !== message.sender.userId) {
            formattedCollection.push({ sender: message.sender, messages: [message] })
        } else {
            let lastEntry = _.last(formattedCollection)
            lastEntry && lastEntry.messages.push(message)
        }
        previousSender = message.sender
    })

    return formattedCollection
}

const label = (date) => moment(date).format('YYYY-MM-DD')

const groupMessagesByTimestamp = messages => messages.reduce((map, current) => {
    const key = label(current.createdDate)

    return {
        ...map,
        [key]: map[key] ? [...map[key], current] : [current]
    }
}, {})

/**
 * @param message
 * @returns {*|{sender: {userId: *}}} or null if message is null
 */
const normalizeMessageSender = (message) => message ? ({
    ...message,
    sender: {
        ...message.sender,
        userId: message.sender.linkedEntity.linkedTo
    }
}) : undefined

export { groupMessagesBySender, groupMessagesByTimestamp, normalizeMessageSender }