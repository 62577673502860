import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import ConversationsList from './ConversationsList'
import Search from '../common/Search'
import { AppGlobalContext } from '../../managers/AppManager'
import { ConversationsContext } from '../../managers/ConversationsManager'
import StyledLink from '../../components/common/StyledLink'
import Typography from '@material-ui/core/Typography'

const ConversationsView = () => {
    const { isMobileDevice, areHeadersSet } = useContext(AppGlobalContext)
    const { searchString, setSearchString, closeConversation, refreshConversationsList, isSearching } = useContext(ConversationsContext)

    const searchConversation = (event) => {
        setSearchString(event.target.value)
        refreshConversationsList(event.target.value, true)
    }

    useEffect(() => {
        areHeadersSet && refreshConversationsList(searchString, true, 0)
    }, [])

    return <StyledGrid
        className='full-height'
        container
        direction='row'
        justify='flex-end'
        style={{ height: '-webkit-fill-available' }}
        data-testid='conversations-view'
    >
        <StyledConversationsWrapper>
            <Search handleInputChange={searchConversation} isSearching={isSearching} searchString={searchString} />
            <ConversationsList />
        </StyledConversationsWrapper>
        <ConversationsStyledLink>
            <StyledLink to='/new'>
                <Fab
                    aria-label='Add'
                    color='secondary'
                    variant={`${isMobileDevice ? 'round' : 'extended'}`}
                    onClick={closeConversation}
                    data-testid='init-conversation-btn-icon'
                >
                    <StyledAddIcon />
                    {!isMobileDevice && <StyledTypography variant='button' data-testid='init-conversation-btn-text' >New Chat</StyledTypography>}
                </Fab>
            </StyledLink>
        </ConversationsStyledLink>
    </StyledGrid>
}

const StyledGrid = styled(Grid)`
    position: relative;
`

const ConversationsStyledLink = styled.div`
    bottom: 16px;
    position: absolute;
    right: 16px;
    z-index: 1;
`

const StyledConversationsWrapper = styled.div`
    width: 100%;
`

const StyledAddIcon = styled(AddIcon)`
    color: #ffffff;
`

const StyledTypography = styled(Typography)`
    -webkit-text-fill-color: #ffffff;
`

export default ConversationsView