import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledSectionContent = ({children}) => {
    return <StyledWrapper>{children}</StyledWrapper>
}

const StyledWrapper = styled.div`
    margin: 0 8px;
`

StyledSectionContent.propTypes = {
    children: PropTypes.object
}

export default StyledSectionContent