import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { AppGlobalContext } from '../managers/AppManager'
import { allcalAppUrl } from '../server'

import useConversations from '../hooks/conversationsHook'
import useMessages from '../hooks/messagesHook'

const ConversationsManager = ({ children, history }) => { // TODO For such a complex state that can be grouped, use 'useReducer'
    const { userChannel, loggedUser, areHeadersSet } = useContext(AppGlobalContext)

    const [conversations, setConversations] = useState([])
    const [isDataLoading, setIsDataLoading] = useState(false)
    const [isSearching, setIsSearching] = useState(false)
    const [hasMoreConversations, setHasMoreConversations] = useState(false)
    const [hasMoreParticipants, setHasMoreParticipants] = useState(false)
    const [searchString, setSearchString] = useState('')
    const [contactsView, setContactsView] = useState('newConversation')
    const [openedConversation, setOpenedConversation] = useState({})
    const [isConversationLoading, setIsConversationLoading] = useState(false)
    const [isSendingMessage, setIsSendingMessage] = useState(false)

    const { getConversations, getConversationById, itemsPerPage, getConversationParticipants } = useConversations()
    const { getMessages } = useMessages()

    let refOpenedConversation = useRef(openedConversation)

    const sortParticipantsList = (participants) => {
        let participantsClone = participants.slice(0)
        const currentUser = _.remove(participantsClone, participant => participant.userId === loggedUser.userId)

        let invitedUsers = _.remove(participantsClone, participant => participant.status === 'pending')
        let blockedUsers = _.remove(participantsClone, participant => participant.isBlocked)
        let deletedUsers = _.remove(participantsClone, participant => participant.status === 'unavailable')

        _.remove(participantsClone, participant => participant.status === 'deleted')

        invitedUsers = _.sortBy(invitedUsers, user => user.name.toLowerCase())
        blockedUsers = _.sortBy(blockedUsers, user => user.name.toLowerCase())
        deletedUsers = _.sortBy(deletedUsers, user => user.name.toLowerCase())

        participantsClone = _.sortBy(participantsClone, user => user.name.toLowerCase())
        const computedList = _.concat([], currentUser, participantsClone, invitedUsers, blockedUsers, deletedUsers)
        return _.uniqBy(computedList, 'userId')
    }

    const openConversation = async (conversationId) => {
        setIsConversationLoading(true)
        const openedConv = await getConversationById(conversationId)
        if (openedConv.error) {
            history.push('/404')
            setIsConversationLoading(false)
            return
        }

        let participants = await getConversationParticipants(openedConv.conversationId)
        if (participants.error) {
            participants = []
        }
        setHasMoreParticipants(participants.length === itemsPerPage)
        setIsConversationLoading(false)

        setOpenedConversation({ ...openedConv, participants: sortParticipantsList(participants) })
        setConversations(prevState => _.map(prevState, (conv) => conv.conversationId === conversationId
            ? ({ ...conv, unreadCount: 0 }) : conv))

        // Tell Allcal I opened another conversation
        window.parent.postMessage({ conversationId }, allcalAppUrl)
    }

    const closeConversation = () => {
        const oldConversationId = openedConversation.conversationId

        setOpenedConversation({})
        if (!_.isEmpty(openedConversation)){
            getMessages(oldConversationId, 0, 0, 1).finally(() => refreshConversationsList('', true))
        }
        else {
            refreshConversationsList('', true)
        }

        history.push('/conversations')
        window.parent.postMessage({}, allcalAppUrl)
    }

    const getUpdatedConversations = async (searchString, skip, at) => {
        let conversationsResponse = await getConversations(searchString, skip, at)
        if (conversationsResponse.error) {
            conversationsResponse = []
        }
        return conversationsResponse
    }

    const orderConversations = (conversations) => {
        return _.orderBy(conversations, conv => conv.lastMessage.createdDate, 'desc')
    }

    const refreshConversationsList = async (searchString, shouldRefreshList, skip, at) => {
        setIsDataLoading(true)
        setIsSearching(!_.isEmpty(searchString))
        let conversationsResponse = await getUpdatedConversations(searchString, skip, at)

        const updatedConversations = shouldRefreshList ?
            conversationsResponse : conversations.concat(conversationsResponse)

        if (at) {
            setConversations(prevState =>
              orderConversations(_.uniqBy(updatedConversations.concat(prevState), 'conversationId')))
        } else {
            setConversations(orderConversations(updatedConversations))
        }
        setHasMoreConversations(conversationsResponse.length === itemsPerPage)
        setIsDataLoading(false)
        setIsSearching(false)
    }

    const getListAfterRealTimeUpdates = (updatedParticipants) => {
        let participantsClone = refOpenedConversation.current.participants.slice(0)
        _.forEach(updatedParticipants, part => {
            const existentIdx = _.findIndex(participantsClone, p => p.userId === part.userId)
            if (existentIdx > -1) {
                participantsClone.splice(existentIdx, 1, part)
            } else {
                participantsClone = _.concat(participantsClone, updatedParticipants)
            }
        })
        return participantsClone
    }

    const refreshConversationParticipantsList = async (conversationId, shouldRefreshList, skip, at) => {
        const convId = conversationId || refOpenedConversation.current.conversationId
        if (refOpenedConversation.current.conversationId !== convId) {
            return
        }
        let participantsResponse = await getConversationParticipants(convId, skip, at)
        if (participantsResponse.error) {
            participantsResponse = []
        }

        let updatedParticipants = shouldRefreshList ?
            participantsResponse : refOpenedConversation.current.participants.concat(participantsResponse)

        if (at) {
            const updatedList = getListAfterRealTimeUpdates(updatedParticipants)
            setOpenedConversation(prevState => ({ ...prevState, participants: sortParticipantsList(updatedList) }))
        } else {
            setOpenedConversation(prevState => ({ ...prevState, participants: sortParticipantsList(updatedParticipants) }))
        }
        setHasMoreParticipants(participantsResponse.length === itemsPerPage)
    }

    const updateConversationLastMessage = (conversationId, message) => {
        const convId = refOpenedConversation.current.conversationId

        setConversations(prevState => {
            const shouldUpdate = prevState.filter(e => e.conversationId === convId)
            if (_.isEmpty(shouldUpdate)) {
                refreshConversationsList('', true)
                return prevState
            }
            return orderConversations(prevState.map(conv =>
                conv.conversationId === conversationId
                    ? ({
                        ...conv,
                        lastMessage: message,
                        unreadCount: convId === conversationId ? 0 : conv.unreadCount + 1,
                        updatedDate: message.updatedDate
                    })
                    : conv));
        })
    }

    const handleMessageEvent = (data) => {
        const { conversationId, message } = data
        if (message) {
            updateConversationLastMessage(conversationId, message)
        }
    }

    const handleConversationsEvent = (data) => {
        const { type } = data

        if (type === 'created') { // Move here data related code
            refreshConversationsList('').finally()
        }
    }

    useEffect(() => {
        if (_.isEmpty(userChannel)) {
            return
        }

        userChannel.bind('messages', handleMessageEvent)
        userChannel.bind('conversations', handleConversationsEvent)

        return () => {
            userChannel.unbind('conversations', handleConversationsEvent)
            userChannel.unbind('messages', handleMessageEvent)
        }
    }, [userChannel])

    useEffect(() => {
        refOpenedConversation.current = openedConversation
    }, [openedConversation])

    useEffect(() => {
        if (_.get(loggedUser, 'loginToken')) {
            refreshConversationsList('', true)
            setSearchString('')
        }
    }, [areHeadersSet])

    return <ConversationsContext.Provider value={{
        isDataLoading,
        isSearching,
        searchString,
        setSearchString,
        conversations,
        setConversations,
        getUpdatedConversations,
        refreshConversationsList,
        openConversation,
        closeConversation,
        hasMoreConversations,
        openedConversation,
        setOpenedConversation,
        hasMoreParticipants,
        sortParticipantsList,
        setHasMoreParticipants,
        contactsView,
        setContactsView,
        orderConversations,
        isConversationLoading,
        isSendingMessage,
        setIsSendingMessage,
        refreshConversationParticipantsList,
        updateConversationLastMessage,
    }}>
        {children}
    </ConversationsContext.Provider>
}

ConversationsManager.propTypes = {
    children: PropTypes.object,
    history: PropTypes.object
}

export const ConversationsContext = createContext({
    isDataLoading: false,
    isSearching: false,
    searchString: '',
    setSearchString: () => { },
    conversations: [],
    setConversations: () => { },
    getUpdatedConversations: () => { },
    refreshConversationsList: () => { },
    openConversation: () => { },
    closeConversation: () => { },
    hasMoreConversations: false,
    openedConversation: {},
    setOpenedConversation: () => { },
    contactsView: 'newConversation',
    setContactsView: () => { },
    isConversationLoading: false,
    isSendingMessage: false,
    setIsSendingMessage: () => { },
    refreshConversationParticipantsList: () => { },
    updateConversationLastMessage: () => { }
})

export default withRouter(ConversationsManager)