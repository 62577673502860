import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import nanoid from "nanoid";

import useMessages from '../hooks/messagesHook'
import { ConversationsContext } from './ConversationsManager'
import { getTextWithFormattedUrls } from '../utils/urlFormatter'
import usePortraitPhotos from '../hooks/portraitPhotos'
import { MessagesContext } from "./MessagesManager";
import { AppGlobalContext } from "./AppManager";
import { normalizeMessageSender } from "../utils/messages";

const defaultMessageObject = {
    message: '',
    media: []
}

const CreateMessageManager = ({ children }) => {
    const imageFileRef = useRef(null)
    const videoFileRef = useRef(null)
    const [imageSrc, setImageSrc] = useState('')
    const [messageObject, setMessageObject] = useState(defaultMessageObject)
    const [isSendTriggered, setIsSendTriggered] = useState(false)
    const [isMediaLoading, setIsMediaLoading] = useState(false)
    const { uploadProgress, uploadFile, getS3Credentials, cancelUpload, sendMessage } = useMessages()
    const { openedConversation, setIsSendingMessage, updateConversationLastMessage } = useContext(ConversationsContext)
    const { setMessages } = useContext(MessagesContext)
    const { fixImageOrientation } = usePortraitPhotos()

    const { loggedUser } = useContext(AppGlobalContext)

    const handleSendMessage = () => {
        if (_.isEmpty(messageObject.media) && !messageObject.message.replace(/\s/g, '').length) {
            return
        }
        setIsSendTriggered(true)
    }

    const clearMediaInputs = () => {
        imageFileRef.current.value = ''
        videoFileRef.current.value = ''
    }

    const removeAttachment = (attachment, idx) => {
        const updatedMediaBulk = messageObject.media.slice(0)
        _.pull(updatedMediaBulk, attachment)
        _.pull(imageFileRef.current.files, (file, index) => index === idx)

        clearMediaInputs()
        setMessageObject({ ...messageObject, media: updatedMediaBulk })
    }

    const updateMessage = (clientKey, message = {}) => {
        setMessages(prevState => {
            return _.uniqBy(prevState.map(e =>
                (e.clientKey === clientKey) ? {
                    ...e,
                    ...message
                } : e
            ), 'messageId')
        })
    }

    const sendMessageToApi = (data) => {
        let iterator = 0
        const messageWithFormattedLinks = getTextWithFormattedUrls(data.message)

        const send = (file) => {
            const messageBody = { message: iterator === 0 ? messageWithFormattedLinks : '', media: file }
            iterator++
            return sendMessage(messageBody, openedConversation.conversationId)
        }

        if (!_.isEmpty(data.media)) {
            setIsSendingMessage(true)

            data.media.reduce(async (prevPromise, nextFile) => {
                await prevPromise
                return send(nextFile)
            }, Promise.resolve())
        } else {
            setIsSendingMessage(false)

            const clientKey = nanoid(32)

            const date = moment().toISOString()
            const eagerMessage = {
                messageId: clientKey,
                sender: {
                    ...loggedUser,
                },
                type: "message",
                message: messageWithFormattedLinks,
                status: "created",
                clientKey: clientKey,
                createdDate: date,
                updatedDate: date,
            }

            setMessages(prevState => [eagerMessage, ...prevState])
            updateConversationLastMessage(openedConversation.conversationId, eagerMessage)

            sendMessage({ message: messageWithFormattedLinks, clientKey }, openedConversation.conversationId)
                .then((message) => {
                    updateMessage(clientKey, normalizeMessageSender(message))
                    updateConversationLastMessage(openedConversation.conversationId, message)
                }).catch(() => {
                updateMessage(clientKey, { error: true });
            })
        }
    }

    const onImageLoaded = async (image) => {
        fixImageOrientation(image.target)
            .then(res => {
                let photoFile = new File([res], '', { type: 'image/jpeg' })
                uploadToS3(photoFile)
            })
    }

    const handleFileUpload = async (file) => {
        setIsMediaLoading(true)

        if (file.type.indexOf('video') !== -1) {
            uploadToS3(file)
            return
        }

        const reader = new FileReader()
        reader.addEventListener('load', () => {
            setImageSrc(reader.result)
        })
        reader.readAsDataURL(file)
    }

    const uploadToS3 = async (file) => {
        const s3CredentialsResponse = await getS3Credentials(file)
        const uploadFileResponse = await uploadFile(file, s3CredentialsResponse.url)
        if (uploadFileResponse.error) {
            return
        }

        const mediaUrl = `https://s3.amazonaws.com/gallery.chat.allcal.com/${s3CredentialsResponse.fileName}`
        setMessageObject(prevState => {
            const mediaBulk = prevState.media.slice(0)
            mediaBulk.push(mediaUrl)
            return { ...prevState, media: mediaBulk }
        })
    }

    const handleMediaAttachmentChange = async (e) => {
        if (_.isEmpty(e.target.files)) {
            return
        }

        const files = Array.from(e.target.files)
        files.reduce(async (prevPromise, nextFile) => {
            await prevPromise
            return handleFileUpload(nextFile)
        }, Promise.resolve())
    }

    const cancelMediaUpload = () => {
        setIsMediaLoading(false)
        removeAttachment()
        cancelUpload && cancelUpload()
    }

    useEffect(() => {
        if (uploadProgress === 0) {
            setIsMediaLoading(false)
        }
    }, [uploadProgress])


    useEffect(() => {
        if (isSendTriggered) {
            sendMessageToApi(messageObject)
            setMessageObject(defaultMessageObject)
            setIsSendTriggered(false)
            clearMediaInputs()
        }
    }, [isSendTriggered])

    useEffect(() => {
        if (imageSrc.indexOf('data:video/mp4') !== -1) {
            return
        }

        let imgElement = document.createElement('img')
        imgElement.src = imageSrc
        imgElement.onload = onImageLoaded
    }, [imageSrc])


    return <CreateMessageContext.Provider value={{
        removeAttachment,
        uploadProgress,
        messageObject,
        setMessageObject,
        isMediaLoading,
        handleSendMessage,
        imageFileRef,
        videoFileRef,
        cancelMediaUpload,
        handleMediaAttachmentChange
    }}>
        {children}
    </CreateMessageContext.Provider>
}

CreateMessageManager.propTypes = {
    children: PropTypes.object
}

export const CreateMessageContext = createContext({
    removeAttachment: () => {},
    uploadProgress: () => {},
    messageObject: {},
    setMessageObject: () => {},
    isMediaLoading: false,
    handleSendMessage: () => {},
    imageFileRef: {},
    videoFileRef: {},
    cancelMediaUpload: () => {},
    handleMediaAttachmentChange: () => {}
})

export default CreateMessageManager