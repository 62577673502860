import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const CustomTooltip = ({ children, tooltipText }) => {
    const [open, setOpen] = useState(false)

    const handleTooltipClose = () => {
        setOpen(false)
    }

    const handleTooltipOpen = () => {
        setOpen(true)
    }

    return <ClickAwayListener onClickAway={handleTooltipClose}>
        <StyledTooltipWrapper>
            <StyledTooltip
                onClose={handleTooltipClose}
                open={open}
                title={tooltipText}
            >
                <div onClick={handleTooltipOpen}>
                    {children}
                </div>
            </StyledTooltip>
        </StyledTooltipWrapper>
    </ClickAwayListener>
}

const StyledTooltipWrapper = styled.div`
    display: flex;
`

const StyledTooltip = styled(Tooltip)`
    margin: 0 auto;
`

CustomTooltip.propTypes = {
    children: PropTypes.object,
    tooltipText: PropTypes.string
}

export default CustomTooltip