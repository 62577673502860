import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Group from '@material-ui/icons/Group'
import UserAvatarInitials from '../common/UserAvatarInitials'

const ConversationPhoto = ({ conversation }) => {
    return conversation.photo ?
        <ListItemAvatar>
            <Avatar alt={conversation.name} src={conversation.photo} data-testid='conversation-photo-avatar' />
        </ListItemAvatar> :
        <StyledDefaultPhoto>
            {conversation.type === '1to1' ? <UserAvatarInitials name={conversation.name} /> : <Group />}
        </StyledDefaultPhoto>
}

const StyledDefaultPhoto = styled.div`
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    color: #9B9B9B;
    display: flex;
    height: 40px;
    margin-right: 16px;
    min-width: 40px;
    width: 40px;
    
    svg {
        margin: 0 auto;
    }
`

ConversationPhoto.propTypes = {
    conversation: PropTypes.object,
    otherParticipant: PropTypes.object
}

ConversationPhoto.defaultProps = {
    conversation: {},
    otherParticipant: {}
}

export default ConversationPhoto