import API, { allCalAPI } from '../server'

const getAllcalContacts = async (searchString) => {
    const allcalContactsResponse = await allCalAPI.get('/user', { searchString })
    return allcalContactsResponse.data
}

const getChatContacts = async (searchString, skip = 0, limit = 20) => {
    const chatContactsResponse = await API.get('/users', { q: searchString, skip, limit })
    return chatContactsResponse.data
}

const blockUser = async (user) => {
    const userResponse = await API.post(`/users/${user.userId}/block`)
    return userResponse.data
}

const unblockUser = async (user) => {
    const userResponse = await API.delete(`/users/${user.userId}/block`)
    return userResponse.data
}

const getBlockedUsers = async () => {
    const blockedUsersResponse = await API.get(`/users/blocks`)
    return blockedUsersResponse.data
}

const inviteContactToAllcal = async (contact) => {
    const invitedContactResponse = await API.get('/users/invitations', { email: contact.email })
    return invitedContactResponse.data
}

const useContacts = () => {
    return {
        getAllcalContacts,
        getChatContacts,
        blockUser,
        unblockUser,
        getBlockedUsers,
        inviteContactToAllcal
    }
}

export default useContacts