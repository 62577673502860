import { useState, useEffect } from 'react'

const useWindowSize = (elementSiblingHeight) => {
    const [siblingHeight, setSiblingHeight] = useState(elementSiblingHeight)
    const [listMaxHeight, setListMaxHeight] = useState(window.innerHeight - siblingHeight)
    useEffect(() => {
        setListMaxHeight(window.innerHeight - siblingHeight)
        const listener = () => {
            setListMaxHeight(window.innerHeight - siblingHeight)
        }

        window.addEventListener('resize', listener)

        return () => {
            window.removeEventListener('resize', listener)
        }
    }, [siblingHeight])

    return { listMaxHeight, setSiblingHeight }
}

export default useWindowSize