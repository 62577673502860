import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ListSubheader from '@material-ui/core/ListSubheader'

const StyledSectionHeader = ({ title }) => {
    return <StyledWrapper>
        {title}
    </StyledWrapper>
}

const StyledWrapper = styled(ListSubheader)`
    && {
        background-color: #e6e6e6;
        color: #4a4a4a;
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        padding: 0 24px;
        text-transform: uppercase;
    }
`

StyledSectionHeader.propTypes = {
    title: PropTypes.string
}

export default StyledSectionHeader