import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'

const EditableConversationDetails = ({ conversation, isNameValid, handleInputChange }) => {
    return <StyledInputsWrapper>
        <StyledTextField
            error={!isNameValid}
            defaultValue={conversation.name}
            onChange={handleInputChange('name')}
            margin='normal'
            inputProps={{ maxLength: 40, 'aria-describedby': 'name-error-text' }}
            placeholder='Group Name'
            data-testid='conversation-details-name'
        />
        {!isNameValid && <StyledFormHelperText id='name-error-text'>Group name is mandatory</StyledFormHelperText>}
        <StyledTextField
            defaultValue={conversation.description}
            onChange={handleInputChange('description')}
            margin='normal'
            inputProps={{ maxLength: 200 }}
            placeholder='Group Description'
            data-testid='conversation-details-description'
        />
    </StyledInputsWrapper>
}

const StyledFormHelperText = styled(FormHelperText)`
    && {
        color: #E76D65;
    }
`

const StyledTextField = styled(TextField)`
    && {
        margin: 8px 0;
        width: 100%;
    }
`

const StyledInputsWrapper = styled.div`
    margin-bottom: 16px;
`

EditableConversationDetails.propTypes = {
    conversation: PropTypes.object,
    isNameValid: PropTypes.bool,
    handleInputChange: PropTypes.func
}

export default EditableConversationDetails