import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

const ConfirmationDialog = ({ children, isOpened, setIsOpened, settings }) => {
    const [dialogActions, setDialogActions] = useState({ cancel: {}, ok: {} })

    const closeDialog = () => {
        setIsOpened(false)
    }

    const proceedWithAction = (action) => {
        settings.okCallback(action)
        closeDialog()
    }

    const setupDialogActions = (action) => {
        setDialogActions({
            cancel: {
                callback: closeDialog,
                text: 'Cancel'
            },
            ok: {
                callback: () => proceedWithAction(action),
                text: 'Yes'
            }
        })
    }

    useEffect(() => {
        setupDialogActions(settings.action)
    }, [isOpened])

    return <StyledDialog
        disableBackdropClick
        disableEscapeKeyDown
        open={isOpened}
        maxWidth='xs'
        aria-labelledby='confirmation-dialog-title'
        data-testid='delete-conversation-dialog'
    >
        <DialogTitle
            id='confirmation-dialog-title'
            data-testid='confirmation-dialog-title'
        >{settings.title}</DialogTitle>
        <DialogContent data-testid='confirmation-dialog-content'>
            {children}
        </DialogContent>
        <DialogActions>
            <Button onClick={dialogActions.cancel.callback} color='secondary'
                data-testid='dialog-cancel-btn'>
                {dialogActions.cancel.text}
            </Button>
            <Button onClick={dialogActions.ok.callback} color='secondary'
                data-testid='dialog-ok-btn'>
                {dialogActions.ok.text}
            </Button>
        </DialogActions>
    </StyledDialog>
}

const StyledDialog = styled(Dialog)`
    color: rgba(0, 0, 0, 0.54);
`

ConfirmationDialog.propTypes = {
    children: PropTypes.any,
    isOpened: PropTypes.bool,
    setIsOpened: PropTypes.func,
    settings: PropTypes.object
}

ConfirmationDialog.defaultProps = {
    children: '',
    isOpened: false,
    setIsOpened: () => { },
    settings: {}
}

export default ConfirmationDialog