import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import AppManager from '../managers/AppManager'
import ConversationsManager from '../managers/ConversationsManager'
import ConversationsPage from './pages/ConversationsPage'
import ConversationPage from './pages/ConversationPage'
import NewConversationPage from './pages/NewConversationPage'
import NotFoundPage from './pages/NotFoundPage'
import AppLoader from './common/AppLoader'

const AppGrid = () => {
  return (
    <AppManager>
      <StyledGrid container spacing={0}>
        <ConversationsManager>
          <>
            <Switch>
              <Redirect from='/' exact to='/conversations' />
              <Route exact path='/conversations' component={ConversationsPage} />
              <Route exact path='/conversations/:id' component={ConversationPage} />
              <Route exact path='/conversations/:id/details' component={ConversationPage} />
              <Route exact path='/new' component={NewConversationPage} />
              <Route path='/404' component={NotFoundPage} />
              <Route component={NotFoundPage} />
            </Switch>
            <AppLoader />
          </>
        </ConversationsManager>
      </StyledGrid>
    </AppManager>
  )
}

const StyledGrid = styled(Grid)`
  overflow: hidden;
  &, & > div {
    height: 100%;
  }

  & > div {
    &:first-child {
      box-shadow: 0 0 4px 0 rgba(153, 153, 153, 0.5);
    }
  }
`

export default AppGrid