export default {
    getPlaceholderBackgroundColor: function (email) {
        let hslValue = 'hsl(' + getEmailValue(email) + ', 100%, 35%)'
        return hslValue

        function getEmailValue(email) {
            let codeValue = email.length
            for (let i = 0; i < email.length; i++) {
                codeValue += email.charCodeAt(i)
            }

            return codeValue % 360
        }

    },
    getUserInitials: function (userName) {
        let names = userName.split(' ')
        let acronym = names[0][0]

        return acronym + (names.length > 1 ? names[1][0] : '')
    }
}
