import React, { useEffect, useRef, useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { ConversationsContext } from './ConversationsManager'
import useMessages from '../hooks/messagesHook'
import { AppGlobalContext } from "./AppManager";
import { normalizeMessageSender } from "../utils/messages";

const MessagesManager = ({ children }) => {
    const [messages, setMessages] = useState([])
    const [isDataLoading, setIsDataLoading] = useState(false)
    const [hasMoreMessages, setHasMoreMessages] = useState(false)
    const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true)

    const { openedConversation } = useContext(ConversationsContext)
    const { messagesPerPage, getMessages } = useMessages()

    let refOpenedConversation = useRef(openedConversation)

    const { userChannel } = useContext(AppGlobalContext)

    const loadMoreMessages = async (conversationId, shouldRefreshMessages, skip, at) => {
        if (conversationId && conversationId !== refOpenedConversation.current.conversationId) {
            return
        }

        setIsDataLoading(true)
        setShouldScrollToBottom(!!at)
        let moreMessages = await getMessages(refOpenedConversation.current.conversationId, skip, at)
        if (moreMessages.error) {
            moreMessages = []
        }

        const updatedMessages = shouldRefreshMessages ? moreMessages : messages.concat(moreMessages)
        if (at) {
            // _.uniqBy is needed because when multiple users send messages in very short time, the updates overlap each other
            setMessages(prevState => _.uniqBy(updatedMessages.concat(prevState), 'messageId'))
            setHasMoreMessages(true)
        } else {
            setMessages(updatedMessages)
            setHasMoreMessages(moreMessages.length === messagesPerPage)
        }

        setIsDataLoading(false)
    }

    const handleMessageEvent = (data) => {
        const { conversationId, message } = data
        const normalizedMessage = normalizeMessageSender(message)

        if (normalizedMessage && openedConversation.conversationId === conversationId) {
            if (normalizedMessage.clientKey) {
                setMessages(prevState => {
                    const messageList = prevState.find(mess =>
                        mess.clientKey === normalizedMessage.clientKey) ? prevState : [normalizedMessage, ...prevState]

                    return orderMessages(_.uniqBy(messageList.map(mess =>
                        mess.clientKey === normalizedMessage.clientKey ? ({
                            ...normalizedMessage
                        }) : mess
                    ), 'messageId'), 'updatedDate', 'desc');
                })
            } else {
                setMessages(prevState => _.uniqBy([normalizedMessage, ...prevState], 'messageId'))
            }
        }
    }

    const orderMessages = (list) => _.orderBy(list, 'updatedDate', 'desc')

    useEffect(() => {
        refOpenedConversation.current = openedConversation
    })

    useEffect(() => {
        !_.isEmpty(openedConversation) && loadMoreMessages(openedConversation.conversationId, true, 0)
    }, [openedConversation])

    useEffect(() => {
        if (_.isEmpty(userChannel)) {
            return
        }

        userChannel.bind('messages', handleMessageEvent)

        return () => {
            userChannel.unbind('messages', handleMessageEvent)
        }
    }, [userChannel])

    return <MessagesContext.Provider
        value={{
            messages,
            isDataLoading,
            setMessages,
            loadMoreMessages,
            hasMoreMessages,
            shouldScrollToBottom,
            setShouldScrollToBottom
        }}
    >{children}</MessagesContext.Provider>
}

MessagesManager.propTypes = {
    children: PropTypes.object
}

export const MessagesContext = createContext({
    messages: [],
    isDataLoading: false,
    loadMoreMessages: () => { },
    hasMoreMessages: false,
    shouldScrollToBottom: true,
    setShouldScrollToBottom: () => { }
})

export default MessagesManager