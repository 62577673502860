import React, { Fragment, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ItemActionsMenu from '../common/ItemActionsMenu'
import useContacts from '../../hooks/contactsHook'
import useConversations from '../../hooks/conversationsHook'
import appMessages from '../../utils/appMessages'
import useConfirmationDialog from '../../hooks/confirmationDialogHook'
import { ConversationsContext } from '../../managers/ConversationsManager'
import ConfirmationDialog from '../common/ConfirmationDialog'

const ConversationActions = ({ history, conversation, otherParticipant }) => {
    const [isMuted, setIsMuted] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [isOptionsOpened, setIsOptionsOpened] = useState(false)

    const { triggerDialog, isDialogOpened, dialogSettings, setIsDialogOpened } = useConfirmationDialog()
    const { deleteConversation,
        getConversationMuteState, muteGroup, unmuteGroup } = useConversations()
    const { blockUser } = useContacts()
    const { openedConversation, setOpenedConversation } = useContext(ConversationsContext)

    const proceedWithConversationAction = (action) => {
        switch (action) {
            case 'deleteConversation':
                deleteConversation(conversation.conversationId)
                break
            case 'blockUser':
                blockConversationParticipant()
                break
            default:
                break
        }
    }

    const triggerDeleteConversation = () => {
        triggerDialog({
            title: 'Delete Conversation',
            action: 'deleteConversation',
            okCallback: proceedWithConversationAction,
            content: appMessages.deleteConversationConfirmation
        })
    }

    const toggleConversationStatus = () => {
        const toggleStatusFunction = isMuted ? unmuteGroup : muteGroup
        toggleStatusFunction(conversation.conversationId)
        setIsMuted(prevState => !prevState)
    }

    const getMenuOptions = () => {
        let options = [
            {
                callback: triggerDeleteConversation,
                className: 'important',
                name: 'Delete Conversation'
            }
        ]

        if (conversation.status !== 'unavailable') {
            options = [{
                callback: toggleConversationStatus,
                className: '',
                name: `${isMuted ? 'Unmute' : 'Mute'} thread`
            }].concat(options)
        }

        if (conversation.type === '1to1') {
            if (otherParticipant.isBlocked) {
                return options
            }

            options = options.concat([{
                callback: triggerBlockUser,
                className: 'important',
                name: 'Block User'
            }])
        }
        return options
    }

    const handleMenuClose = () => {
        setIsOptionsOpened(false)
    }

    const triggerBlockUser = () => {
        triggerDialog({
            title: 'Block User',
            action: 'blockUser',
            okCallback: proceedWithConversationAction,
            content: appMessages.blockUserConfirmation
        })
    }

    const blockConversationParticipant = async () => {
        blockUser(otherParticipant)

        if (conversation.conversationId === openedConversation.conversationId) {
            history.push('/conversations')
            setOpenedConversation({})
        }
    }

    const getConversationStatus = async () => {
        const status = await getConversationMuteState(conversation.conversationId)
        setIsMuted(status.muted)
    }

    const handleConversationClick = async (event) => {
        setAnchorEl(event.currentTarget.parentElement)
        await getConversationStatus()
        setIsOptionsOpened(true)
    }

    return <Fragment>
        <StyledMoreVertIcon onClick={handleConversationClick} />
        <ItemActionsMenu
            options={getMenuOptions()}
            isOpened={isOptionsOpened}
            anchorEl={anchorEl}
            onClose={handleMenuClose}
        />
        <ConfirmationDialog
            isOpened={isDialogOpened}
            setIsOpened={setIsDialogOpened}
            settings={dialogSettings}
        >{dialogSettings.content}</ConfirmationDialog>
    </Fragment>
}

const StyledMoreVertIcon = styled(MoreVertIcon)`
    && {
        width: 1.5em;
    }
`

ConversationActions.propTypes = {
    history: PropTypes.shape(),
    conversation: PropTypes.shape(),
    otherParticipant: PropTypes.shape()
}

export default withRouter(ConversationActions)