import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ParticipantsList from './ParticipantsList'
import CustomTooltip from '../common/CustomTooltip'

const ConfirmationButton = ({ viewSettings, disabled }) => <StyledButton color='secondary' disabled={disabled} onClick={viewSettings.confirmationClickHandler}
    size='small' variant='contained'
    data-testid='start-conversation-btn'
>
    {viewSettings.confirmationButtonText}
</StyledButton>

const NewConversation = ({ removeUser, users, activeView, viewSettings }) => {
    const isInvalidConversation = () => users.length === 1 && users[0].isBlocked && activeView === 'newConversation'

    return <StyledNewConversationWrapper data-testid='new-conversation-wrapper'>
        <Typography component='p'>{viewSettings.selectedCollectionName}</Typography>
        <ParticipantsList removeUser={removeUser} users={users} />
        {isInvalidConversation() ? <CustomTooltip tooltipText='This person is blocked. You cannot send private messages to this person.'>
            <ConfirmationButton disabled viewSettings={viewSettings} />
        </CustomTooltip> : <ConfirmationButton viewSettings={viewSettings} />}
    </StyledNewConversationWrapper>
}

const StyledNewConversationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 24px;
`

const StyledButton = styled(Button)`
    && {
        align-self: center;
        border-radius: 15px;
        color: #ffffff;
        font-weight: 700;
        margin: 16px 0px;
        min-width: 176px;
    }
`

ConfirmationButton.propTypes = {
    viewSettings: PropTypes.object,
    disabled: PropTypes.bool
}

NewConversation.propTypes = {
    removeUser: PropTypes.func,
    activeView: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.object),
    viewSettings: PropTypes.object
}

export default NewConversation