import React, { Fragment, useState, useContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Group from '@material-ui/icons/Group'
import { Button } from '@material-ui/core'
import CameraAlt from '@material-ui/icons/CameraAlt'
import EditableConversationDetails from './EditableCoversationDetails'
import ImageCropperDialog from '../common/ImageCropperDialog'
import useMessages from '../../hooks/messagesHook'
import { ConversationsContext } from '../../managers/ConversationsManager'

const ConversationGroupInfo = ({ isInEditMode, editedConversation, setEditedConversation, isNameValid, setIsNameValid }) => {
    const [hasChangePhotoLayer, setHasChangePhotoLayer] = useState(false)
    const [isDialogOpened, setIsDialogOpened] = useState(false)
    const [selectedPhoto, setSelectedPhoto] = useState()
    const [newImage, setNewImage] = useState()

    const { openedConversation, setOpenedConversation } = useContext(ConversationsContext)
    const { uploadFile, getS3Credentials } = useMessages()
    const refNewImage = useRef(newImage)
    const imageFileRef = useRef(null)

    const handleHoverCoverPhoto = (nextState) => {
        setHasChangePhotoLayer(isInEditMode && nextState)
    }

    const handleInputChange = name => event => {
        const inputText = event.target.value
        name === 'name' && setIsNameValid(!!inputText.match(".*\\w.*"))
        setEditedConversation({ ...editedConversation, [name]: inputText })
    }

    const triggerChooseImageFile = () => imageFileRef.current.click()

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedPhoto(e.target.files[0])
            setIsDialogOpened(true)

            // TODO: remove after bringing back the imageCropper component
            setNewImage(e.target.files[0])
        }
    }

    const handlePhotoUpload = async () => {
        let photoFile = new File([refNewImage.current], '', { type: 'image/jpeg' })
        const s3CredentialsResponse = await getS3Credentials(photoFile)
        const uploadFileResponse = await uploadFile(photoFile, s3CredentialsResponse.url)
        if (uploadFileResponse.error) {
            return
        }

        return `https://s3.amazonaws.com/gallery.chat.allcal.com/${s3CredentialsResponse.fileName}`
    }

    const proceedWithChangeGroupPhoto = async () => {
        const photoUploadResponse = await handlePhotoUpload()
        setEditedConversation(prevState => ({ ...prevState, photo: photoUploadResponse }))
        setOpenedConversation(prevState => ({ ...prevState, photo: photoUploadResponse }))
    }

    const dialogSettings = {
        title: 'Change Group Photo',
        okCallback: proceedWithChangeGroupPhoto
    }

    useEffect(() => {
        refNewImage.current = newImage
        imageFileRef.current.value = ''
    }, [newImage])

    return <StyledPrimaryGroupInfoWrapper
        data-testid='conversation-details-wrapper'
    >
        <StyledConversationPhoto
            hasphoto={!!openedConversation.photo}
            onMouseEnter={() => handleHoverCoverPhoto(true)}
            onMouseLeave={() => handleHoverCoverPhoto(false)}
            onTouchStart={() => handleHoverCoverPhoto(true)}
            data-testid='conversation-details-photo'
        >
            {openedConversation.photo ?
                <img
                    alt='conversation-avatar'
                    src={openedConversation.photo}
                    data-testid='conversation-photo'
                /> :
                <Group />
            }
            {hasChangePhotoLayer &&
                <StyledChangePhotoLayer onClick={triggerChooseImageFile}
                    data-testid='change-photo-layer'
                >
                    <CameraAlt />Change Picture
                            </StyledChangePhotoLayer>
            }
        </StyledConversationPhoto>
        {isInEditMode ?
            <EditableConversationDetails
                conversation={openedConversation}
                isNameValid={isNameValid}
                handleInputChange={handleInputChange}
            /> :
            <Fragment>
                <StyledConversationName
                    variant='h6'
                    data-testid='conversation-details-name'
                >
                    {openedConversation.name}
                </StyledConversationName>
                {openedConversation.description &&
                    <StyledConversationDescription
                        data-testid='conversation-details-description'
                    >
                        {openedConversation.description}
                    </StyledConversationDescription>}
            </Fragment>
        }
        <ImageCropperDialog
            isDialogOpened={isDialogOpened}
            setIsDialogOpened={setIsDialogOpened}
            dialogSettings={dialogSettings}
            selectedImage={selectedPhoto}
            setNewImage={setNewImage}
        />
        <input style={{ display: 'none' }} ref={imageFileRef} type='file' accept='image/*' onChange={onSelectFile} />
    </StyledPrimaryGroupInfoWrapper>
}

const StyledConversationName = styled(Typography)`
    max-height: 3.2em;
    margin-right: -1em;
    overflow: hidden;
    padding-right: 1em;
    position: relative; 
    text-align: justify;  
    word-break: break-all;

    &:before {
        bottom: 0;
        content: '...';
        position: absolute;
        right: 0;
    }

    &:after {
        background: white;
        content: '';
        height: 1em;
        margin-top: 0.2em;
        position: absolute;
        right: 0;
        width: 1em;
    }
`

const StyledChangeImageButton = styled(Button)`
    && {
        bottom: 0;
        font-size: 12px;
        padding: 2px 4px;
        position: absolute;
        right: 0;

        &, &:hover, &:active {
            background-color: #ffffff;
        }
    }
`

const StyledConversationPhoto = styled.div`
    align-items: center;
    background-color: ${props => props.hasphoto ? '#FFFFFF' : '#D0D0D0'};
    border-radius: 8px;
    color: #9B9B9B;
    display: flex;
    height: 172px;
    margin-bottom: 8px;
    position: relative;

    svg {
        margin: 0 auto;
        font-size: 80px;
    }

    ${StyledChangeImageButton} svg {
        font-size: 16px;
    }

    img {
        margin: 0 auto;
        max-height: 100%;
        max-width: 100%;
    }
`

const StyledChangePhotoLayer = styled.div`
    align-items: center;
    background-color: #000000;
    cursor: pointer;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    height: 100%;
    justify-content: center;
    opacity: 0.5;
    position: absolute;
    width: 100%;

    svg {
        margin: 0;
        font-size: 24px;
    }
`

const StyledPrimaryGroupInfoWrapper = styled.div`
    padding: 0 16px;
`

const StyledConversationDescription = styled(Typography)`
    && {
        margin: 8px 0 16px 0;
    }
`

ConversationGroupInfo.propTypes = {
    isNameValid: PropTypes.bool,
    setIsNameValid: PropTypes.func,
    isInEditMode: PropTypes.bool,
    editedConversation: PropTypes.object,
    setEditedConversation: PropTypes.func
}

export default ConversationGroupInfo