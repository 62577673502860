import { setPusherClient } from 'react-pusher'
import Pusher from 'pusher-js'
import { pusherSettings, environment } from '../server.js'

let pusherClient = {}

const pusherSetup = (loginToken) => {
  Pusher.logToConsole = false

  pusherClient = new Pusher(pusherSettings.appKey, {
    cluster: 'mt1',
    forceTLS: true,
    authEndpoint: pusherSettings.authEndpoint,
    auth: {
      headers: { 'login-token': loginToken, 'service': 'Allcal' }
    }
  })

  pusherClient.connection.bind('error', function (err) {
    if (err.error) {
      console.log('Oh nooo! Pusher binding error occurred.', err.error)
    }
  })

  setPusherClient(pusherClient)

  return pusherClient
}

export const subscribeToUserChannel = (userId) => {
  var userChannel = pusherClient.subscribe(`private-Allcal@${environment}-${userId}`)

  userChannel.bind('pusher:subscription_error', function (status) {
    console.log('Pusher Subscription error', status)
  })

  return userChannel
}

export const unsubscribeFromUserChannel = (userId) => {
  pusherClient.unsubscribe(userId)
}

export default pusherSetup