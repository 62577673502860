import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const LoadingLayer = ({ position }) => {
    return <StyledLayer position={position}>
        <StyledCircularProgress color='secondary' />
    </StyledLayer>
}

LoadingLayer.propTypes = {
    position: PropTypes.string
}

LoadingLayer.defaultProps = {
    position: 'absolute'
}

const StyledLayer = styled.div`
    background-color: rgba(255, 255, 255, 0.7);
    height: 100%;
    position: ${props => props.position};
    top: 0;
    width: 100%;
    z-index: 1;
`

const StyledCircularProgress = styled(CircularProgress)`
    left: calc(50% - 20px);
    position: relative;
    top: calc(50% - 20px);
`

export default LoadingLayer
