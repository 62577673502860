import React, { Fragment, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import ConversationWindow from '../ConversationWindow/ConversationWindow'
import ConversationContactsView from '../SideBar/ConversationContactsView'
import { AppGlobalContext } from '../../managers/AppManager'
import ContactsManager from '../../managers/ContactsManager'
import { ConversationsContext } from '../../managers/ConversationsManager'
import useSiblingWidth from '../../hooks/siblingWidthHook'
import useHookWithRefCallback from '../../hooks/refCallbackHook'

const MobileView = () => {
    return <Hidden smUp>
        <Grid item xs={12} className='full-height'>
            <Slide direction='up' in>
                <div className='full-height'>
                    <ContactsManager>
                        <ConversationContactsView />
                    </ContactsManager>
                </div>
            </Slide>
        </Grid>
    </Hidden>
}

const DesktopView = () => {
    const { maxWidth, setSibling } = useSiblingWidth()
    const [refSibling] = useHookWithRefCallback(setSibling)

    return <Hidden xsDown>
        <Grid item sm={5} md={5} lg={4} style={{ minWidth: '305px' }} ref={refSibling}>
            <div className='full-height' data-testid='sidebar'>
                <ContactsManager>
                    <ConversationContactsView />
                </ContactsManager>
            </div>
        </Grid>
        <Grid item sm={7} md={7} lg={8} style={{ maxWidth: `${maxWidth}px` }}>
            <ConversationWindow />
        </Grid>
    </Hidden>
}

const NewConversationPage = ({ history }) => {
    const { areHeadersSet } = useContext(AppGlobalContext)
    const { setContactsView } = useContext(ConversationsContext)

    useEffect(() => {
        if (!areHeadersSet) {
            history.push('/conversations')
        }
        setContactsView('newConversation')
    }, [])

    return <Fragment>
        {areHeadersSet &&
            <Fragment>
                <DesktopView />
                <MobileView />
            </Fragment>
        }
    </Fragment>
}

NewConversationPage.propTypes = {
    history: PropTypes.object
}

export default withRouter(NewConversationPage)