import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'

const ConversationSystemMessage = ({ messageItem }) => {
    return <StyledChip label={messageItem.message} />
}

const StyledChip = styled(Chip)`
    && {
        height: unset;
        margin-bottom: 10px;
        min-height: 32px;
    }

    span {
        padding: 4px 12px;
        text-align: center;
        white-space: normal;
        word-wrap: break-word;
    }
`

ConversationSystemMessage.propTypes = {
    messageItem: PropTypes.object
}

ConversationSystemMessage.defaultProps = {
    messageItem: {}
}

export default ConversationSystemMessage