import React, { useContext, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withTheme } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import UserAvatar from '../common/UserAvatar'
import styled from 'styled-components'
import { AppGlobalContext } from '../../managers/AppManager'
import formatDate from '../../utils/dateFormatter'
import MessageCore from './MessageCore'

const MessageGroup = ({ messageGroup, theme }) => {
    const { loggedUser } = useContext(AppGlobalContext)

    const shouldShowSenderName = (message) => {
        return message.messageId === _.last(messageGroup.messages).messageId
    }

    const isMine = (messageGroup) => {
        return messageGroup.sender.userId === loggedUser.userId
    }

    const renderMessageGroup = () => {
        return <Fragment>
            <UserAvatar user={messageGroup.sender} />
            <StyledMessageGroup>
                {
                    _.map(messageGroup.messages, message => <StyledMessageContent key={message.messageId} data-testid='message-wrapper'>
                        {shouldShowSenderName(message) && <Typography variant='body2' data-testid='message-sender-name'>{messageGroup.sender.name}</Typography>}
                        <StyledMessageContainer>
                            <MessageCore message={message} isMine={isMine(messageGroup)} />
                            <StyledMessageDate variant='body2' data-testid='message-date'>{formatDate(message.createdDate)}</StyledMessageDate>
                        </StyledMessageContainer>
                        {message.error && <ErrorMessage>Error! Message not sent!</ErrorMessage>}
                    </StyledMessageContent>
                    )
                }
            </StyledMessageGroup>
        </Fragment>
    }

    return <StyledMessageGroupWrapper theme={theme} className={isMine(messageGroup) && 'my-message'} data-testid='message-group-wrapper'>
        {renderMessageGroup()}
    </StyledMessageGroupWrapper>
}

const StyledMessageGroup = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
`

const StyledMessageContent = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: 0 10px 10px;
`

const StyledMessageContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
`

const StyledMessageDate = styled(Typography)`
    min-width: fit-content;
    padding-left: 10px;
    padding-right: 0;
`

const StyledMessageGroupWrapper = styled.div`
    align-items: flex-start;
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    max-width: 70%;
    min-width: 70%;
    word-break: break-word;

    ${props => props.theme.breakpoints.down('sm')} {
        max-width: 100%;
        min-width: 100%;
    }

    &.my-message {
        align-self: flex-end;
        flex-direction: row-reverse;

        ${StyledMessageGroup}, ${StyledMessageContent} {
            align-items: flex-end;
        }

        ${StyledMessageContainer} {
            flex-direction: row-reverse;
        }

        ${StyledMessageDate} {
            padding-right: 10px;
            padding-left: 0;
        }
    }
`

const ErrorMessage = styled.div`
  color: #f44336; // material red 500
  font-size: 12px;
`

MessageGroup.propTypes = {
    messageGroup: PropTypes.object,
    theme: PropTypes.object
}

MessageGroup.defaultProps = {
    theme: {
        breakpoints: {}
    }
}

export default withTheme(MessageGroup)