import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CreateMessageMediaActions from '../ConversationWindow/CreateMessageMediaActions'
import Send from '@material-ui/icons/Send'
import IconButton from '@material-ui/core/IconButton'
import { CreateMessageContext } from '../../managers/CreateMessageManager'

const CreateMessageActions = ({ isMobileView }) => {
    const { handleSendMessage } = useContext(CreateMessageContext)

    return <StyledMessageActions data-testid='new-message-actions'>
        <CreateMessageMediaActions isMobileView={isMobileView} />
        <StyledIconButton aria-label='Send Message' onClick={handleSendMessage} data-testid='send-message'>
            <Send fontSize='small' />
        </StyledIconButton>
    </StyledMessageActions>
}

const StyledMessageActions = styled.div`
    display: flex;
    justify-content: flex-end;
    min-width: 108px;
`

const StyledIconButton = styled(IconButton)`
    && {
        color: #4a4a4a;
        padding: 8px;
    }
`

CreateMessageActions.propTypes = {
    isMobileView: PropTypes.bool
}

export default CreateMessageActions